import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-sale/G_TagsViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';

@Component({
  selector: 'app-purchase-pdf-invoice',
  templateUrl: './purchase-pdf-invoice.component.html',
  styleUrls: ['./purchase-pdf-invoice.component.scss']
})
export class PurchasePdfInvoiceComponent implements OnInit {

  modalRef: BsModalRef;
  disabled = true;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public component_Title = 'Purchase Invoice';
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_PurchaseMasterViewModel = new G_PurchaseMasterViewModel();
  public purchaseDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public purchaseDetail: any[];
  public csaleDetail: any[];
  public suppliers: any = [];
  public selectedSuppliers: any = [];
  public thumbnail: any;



  constructor(private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router) {
  }

  ngOnInit() {
    this.vm = new G_PurchaseMasterViewModel();
    this.purchaseDetailVM = new PurchaseDetailViewModel();
    //console.log(this.vm);
    let id = this.route.snapshot.params['id'];
    this._uow.purchaseService.GetById(id).subscribe((res: any) =>{
      console.log(res);
      this.vm = res;
    },(err)=>{
      console.log(err);
    })

    this.getimage();
  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
        }else{
          this.thumbnail = baseImage.ComName;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }

  getSupCashList() {
    this.selectedSuppliers = [];
    this.getSuppliers();
  }

  getSuppliers() {
    let supllier = "Supplier";
    if (this.vm.PaymentType === 2) {
      supllier = "Cash";
    }

    this._uow.supplierService.GetListSuplier(supllier).subscribe((res: any) => {
      this.suppliers = res.DtlList;
      this._uow.logger.log(this.suppliers);
    }, (err) => this._uow.logger.log(err));
  }

  public TotalPayable = 0;
  public TotalVat = 0;
  public TotalPayableAfterVat = 0;
  updateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    if (this.vm.PurchaseDetail !== undefined && this.vm.PurchaseDetail.length > 0) {
      this.vm.PurchaseDetail.forEach(x => {
        this.TotalPayable += x.TotalAmount;
        this.TotalVat += x.VatAmount;
        this.TotalPayableAfterVat += (x.TotalAmount + x.VatAmount);
        this.vm.Inv_Amount = this.TotalPayableAfterVat;
      });
    }
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {

    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }

  onButtonClick() {
    this._jq.showSpinner(true);
    this._router.navigate(['/purchase'])
  }


  onItemSubmitted(e) {
    if (e == true) {
      //this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getSpareParts() {
    //'Spare-Parts'
    let supllierrr = "Spare-Parts";
    this._uow.itemService.GetSSItemDefiningByItemType(supllierrr).subscribe((res: any) => {
      this.spareParts = res;
      console.log(res);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
  }
}
