import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  public deleteAndSetCookie(name: string, val: string) {
    this.deleteCookie(name);
    this.setCookie(name, val);
  }

  public setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
    // Set it
    document.cookie = `${name} = ${value}; expires= ${date.toUTCString()}; path=/`;
    //  + "=" + value+ "; expires=" + date.toUTCString() + "; path=/";
  }

  // public getCookie(name: string) {

  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name} = `);
  //   if (parts.length === 2) {
  //     return parts.pop().split(';').shift();
  //   }
  // }

  public deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = `${name} =; expires= ${date.toUTCString()}; path=/`;
  }


  // function setCookie(cname, cvalue, exdays) {
  //   var d = new Date();
  //   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  //   var expires = "expires="+d.toUTCString();
  //   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  // }

  public getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // function checkCookie() {
  //   var user = getCookie("username");
  //   if (user != "") {
  //     alert("Welcome again " + user);
  //   } else {
  //     user = prompt("Please enter your name:", "");
  //     if (user != "" && user != null) {
  //       setCookie("username", user, 365);
  //     }
  //   }
  // }
}
