import { Component, OnInit, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { G_BrandsViewModel } from 'src/app/viewmodels/g-item/G_BrandsViewModel';
import { G_ModelsViewModel } from 'src/app/viewmodels/g-settings/G_ModelsViewModel';
//import { AccountDefinigDtlView } from 'src/app/viewmodels/';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GItemQRViewModel } from 'src/app/viewmodels/g-item/GItemQRViewModel';
import { GItemModelViewModel } from 'src/app/viewmodels/g-item/GItemModelViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GItemSupplierViewModel } from 'src/app/viewmodels/g-item/GItemSupplierViewModel';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent implements OnInit {
  @Input() public vm: GItemDefiningViewModel;
  @Input() public forItemType: '';
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() public vmChange = new EventEmitter<GItemDefiningViewModel>();
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  modalRef: BsModalRef;
  modalRefLv2: BsModalRef;

  public ctagtype = 'tagtype';
  public tagTypes: any = [];

  public cssCategory = 'sscategory';
  public ssCategories: any = [];

  public ccolors = 'colors';
  public colors: any = [];

  public cunit = 'unit';
  public unit: any = [];


  public citemtype = 'itemtype';
  public itemTypes: any = [];

  public cmanufecturers = 'manufecturers';
  public manufecturers: any = [];

  public csuppliers = 'suppliers';

  public cbrands: any = 'brands';
  public brands: any = [];
  public selectedBrands: any = [];

  public cmodels = 'models';
  public models: any = [];
  public selectedModels: any = [];

  public suppliers: any = [];
  public selectedSuppliers: any = [];

  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    showSelectedItemsAtTop: true,
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    showSelectedItemsAtTop: true,
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    showSelectedItemsAtTop: true,
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  name = 'GenericModel';
  id = 'Id';
  checked = false;
  disabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getSuppliers();
    this.getItemTypes();
    this.getManufecturers();
    this.getSsCategories();
    this.getcolors();
    this.getunits();
    this.getBrands();
    debugger
    this.vm.IsGeneric=false;
    if (this.vm == undefined) {
      this.vm = new GItemDefiningViewModel();
      this.vm.GItemQRCode = [];
      this.vm.FK_G_ItemType_Id = new ItemViewModel(0, '');
      this.vm.IsGeneric = false;
    }
    else{
      let slectedSuplierList : any = [];
      let slecbrandlist : any = [];
      let slectedModellist : any = [];

      if (this.vm.GItemSupplier != undefined && this.vm.GItemSupplier.length > 0) {
        setTimeout(() => {
          this.vm.GItemSupplier.forEach(x=> {
            console.log(this.suppliers.length);
            let selectedSupliers = this.suppliers.find(y => y.Id == x.Fk_Supplier_Id.Id);
             
            if (selectedSupliers != undefined && selectedSupliers != null) {
             
              slectedSuplierList.push(selectedSupliers);
             }
          })
          this.selectedSuppliers = slectedSuplierList; 
        }, 2000);
       
      }

      if (this.vm.GItemModels != undefined && this.vm.GItemModels.length > 0) {
        this.vm.GItemModels.forEach(x=> {
          let sM = new G_ModelsViewModel();
          sM.Id = x.Fk_G_Models.Id;
          sM.Fk_G_Brand_Id = new ItemViewModel(x.Fk_G_Brands.Id, x.Fk_G_Brands.Name);
          sM.Name = x.Fk_G_Models.Name,
          slectedModellist.push(sM);
          let brandalready = slecbrandlist.find(y => y.Id == x.Fk_G_Brands.Id);
          if (brandalready == null || brandalready == undefined) {
            let sB = new G_BrandsViewModel();
            sB.Id  =  x.Fk_G_Brands.Id;
            sB.Name = x.Fk_G_Brands.Name;
            slecbrandlist.push(sB);
          }
        })
        this.selectedModels = slectedModellist;
        this.selectedBrands = slecbrandlist;
        this.getModelsForBrands(null);
      }
    }
    if (this.vm.Code == undefined) {
      this._uow.itemService.GetCode().subscribe((res: any) => {
        this._uow.logger.log(res);
        this.vm.Code = res.code;
      }, (err) => this._uow.logger.log(err));
    }

    this.vmChange.emit(this.vm);
    this._uow.logger.log(this.vm);

    if (this.modal_title == undefined) {
      this.modal_title = 'Item';
    }

  }

  onChange(e) {
    // debugger
    console.log(e)
    if(e){
      this.vm.IsGeneric = true;
    }
    if(!e){
      this.vm.IsGeneric = false;
    }
    console.log(this.vm.IsGeneric);
}

  saveOrUpdate() {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.logger.log(this.vm);
    this.vm.GItemModels = [];
    this.vm.GItemSupplier = [];
    if (this.selectedModels !== undefined && this.selectedModels.length > 0) {
      this.selectedModels.forEach(m => {
        let model = this.models.find(x => x.Id == m.Id);
        this._uow.logger.log(model);
        if (model !== undefined) {
          let mi = new GItemModelViewModel();
          mi.Id = model.Id;
          mi.Fk_G_Brands = new ItemViewModel(model.Fk_G_Brand_Id.Id, model.Fk_G_Brand_Id.Name);
          mi.Fk_G_Models = new ItemViewModel(model.Id, model.Name);
          this.vm.GItemModels.push(mi);
        }
      });
    }
    if (this.selectedSuppliers !== undefined && this.selectedSuppliers.length > 0) {
      this.selectedSuppliers.forEach(s => {
        let model = this.suppliers.find(x => x.Id == s.Id);
        this._uow.logger.log(model);
        if (model !== undefined) {
          let si = new GItemSupplierViewModel();
          si.Id = model.Id;
          si.Fk_Supplier_Id = new ItemViewModel(model.Id, model.Acc_Titel);
          this.vm.GItemSupplier.push(si);
        }
      });
    }
    this._uow.itemService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        this._jq.showSpinner(true);
        this._uow.logger.log(res);
        this.submitted = true;
        
        
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Item Created/Updated!',
          'success'
        );
        
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire(
          'Failure!',
          'Item Not Updated!',
          'error'
        )
        this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });
  }

  checkItemDublication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.itemService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

  onItemDSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.ngOnInit();
    }
  }

  // exteral coponents
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }
  openTagTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  getTagTypes() {
    this._uow.tagsTypeService.GetList().subscribe((res: any) => {
      this.tagTypes = res;
    }, (err) => this._uow.logger.log(err));
  }
  getSsCategories() {
    this._uow.ssCategoryService.GetList().subscribe((res: any) => {
      this.ssCategories = res;
    }, (err) => this._uow.logger.log(err));
  }
  getcolors() {
    this._uow.colourService.GetList().subscribe((res: any) => {
      this.colors = res;
    }, (err) => this._uow.logger.log(err));
  }
  getunits() {
    this._uow.unitService.GetList().subscribe((res: any) => {
      this.unit = res;
    }, (err) => this._uow.logger.log(err));
  }
  getItemTypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => {
      this.itemTypes = res;
    }, (err) => this._uow.logger.log(err));
  }
  getManufecturers() {
    this._uow.manufacturerService.GetList().subscribe((res: any) => {
      this.manufecturers = res;
    }, (err) => this._uow.logger.log(err));
  }
  getSuppliers() {
    this._uow.supplierService.GetList().subscribe((res: any) => {
      this.suppliers = res.DtlList;
      this._uow.logger.log(this.suppliers);
    }, (err) => this._uow.logger.log(err));
  }
  getBrands() {
    this._uow.brandService.GetList().subscribe((res: any) => {
      this.brands = res;
    }, (err) => this._uow.logger.log(err));
  }

  // get modesl
  getModelsForBrands(e) {
    var ids = [];
    this.selectedBrands.forEach(b => {
      ids.push(b.Id);
    });
    this._uow.modelService.GetModelsListByBrands(ids).subscribe((res: any) => {
      this.models = res;
      if (this.selectedModels.length > 0) {
        this.selectedModels.forEach(m => {
          let f = this.models.find(x => x.Id == m.Id);
          this._uow.logger.log(m);
          if (f == undefined) {
            this.onModelSelected(m);
          }
        });
      }
      this._uow.logger.log(this.models);
    }, (error) => {
      this._uow.logger.log(error);
    });
  }

  getModels() {
    this._uow.modelService.GetList().subscribe((res: any) => {
      this.models = res;
    }, (err) => {
      this._uow.logger.log(err);
    });
  }

  // selects
  onBrandSelect(e) {
    this.getModelsForBrands(null);
  }
  onBrandDeSelected(e) {
    this.getModelsForBrands(null);
  }
  onModelSelected(e) {
    this._uow.logger.log(e.Id);
    // let model = this.models.find(x => x.Id == e.Id);
    // if (model !== undefined) {
    //   this.vm.GItemModels.push(model);
    // }
  }
  onModelDeSelected(e) {
    this.selectedModels.splice(this.selectedModels.indexOf(e), 1);
    // let model = this.models.find(x => x.Id == e.Id);
    // if (model !== undefined) {
    //   this.vm.GItemModels.splice(this.vm.GItemModels.indexOf(model), 1);
    // }
  }
  onSupplierSelect(e) {
    this._uow.logger.log(e);
  }
  onSupplierDeSelect(e) {
    this._uow.logger.log(e);
  }

  // qr
  onGenerateQrCode() {
    this.getQrCode();
  }
  onRemoveQrCode(obj) {
    this._uow.logger.log(obj)
    let item = this.vm.GItemQRCode.find(x => x.Code == obj.Code);
    if (item !== undefined) {
      this.vm.GItemQRCode.splice(this.vm.GItemQRCode.indexOf(item), 1);
    }
  }

  getQrCode() {
    if (this.vm.GItemQRCode == undefined || this.vm.GItemQRCode.length == 0) {
      this.vm.GItemQRCode = [];
    }
    this._uow.itemService.ValidateItemQRCode().subscribe((res: any) => {
      debugger
      let item = new GItemQRViewModel();
      item.Code = `${this.vm.Code}${res.code}`;
      this.vm.GItemQRCode.push(item);
    }, (err) => this._uow.logger.log(err));
  }

  // extrnal components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {
      if (mf == this.ctagtype) {
        this.getTagTypes();
      }
      if (mf == this.citemtype) {
        this.getItemTypes();
      }
      if (mf == this.cmanufecturers) {
        this.getManufecturers();
      }
      if (mf == this.csuppliers) {
        this.getSuppliers();
      }
      if (mf == this.cbrands) {
        this.getBrands();
      }
      if (mf == this.cmodels) {
        this.getModelsForBrands(e);
      }
    }
  }
  ////////////warehouse wise list////////////////
  // getwareHouseItemList(id) {
  //   // this._jq.showSpinner(true);
  //   // this.spinner.show();
  //   debugger;
  //   this._uow.wareHouseItemService.GetListItem(id).subscribe((res: Array<any>) => {
  //     // this._jq.reinitDataTable(res);
  //     res.forEach(element =>{
  //       element.FK_WareHouse_Id = new ItemViewModel(this.FK_WareHouse_Id.Id,this.FK_WareHouse_Id.Name);
  //     });
  //     this.warehouseItemVmlist = res;
  //     console.log('list item',res);
  //     // this.spinner.hide();
  //     // this._jq.showSpinner();
  //   }, (err) => {
  //     console.log(err);
  //     // this.spinner.hide();
  //   });
  // }
}
