export class ItemViewModel {
    constructor(id?: number, name?: string) {
        this.Id = id;
        this.Name = name;
    }
    public Id: number;
    public Name: string;
}
export class StringItemViewModel{
    constructor(id?: string, name?: string) {
        this.Id = id;
        this.Name = name;
    }
    public Id: string;
    public Name: string; 
}
export class WareHouseViewModel{
    public FK_WareHouse_Id:ItemViewModel;
}