import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { G_FuelTypeViewModel } from 'src/app/viewmodels/g-settings/G_FuelTypeViewModel';
import { IHttpService } from '../../common/IHttpService';

@Injectable({
  providedIn: 'root'
})
export class FuleTypeService implements IHttpService<G_FuelTypeViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_FuelTypeList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_FuelTypeDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_FuelTypeById'), { Id: id });
  }
  Post(obj: G_FuelTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_FuelType'), obj);
  }

  Put(obj: G_FuelTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_FuelType'), obj);
  }

  Delete(obj: G_FuelTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_FuelType'), obj);
  }

}
