import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GAggingReportViewmodel } from 'src/app/viewmodels/g-settings/GAggingReportViewmodel';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-agging-report',
  templateUrl: './agging-report.component.html',
  styleUrls: ['./agging-report.component.scss']
})
export class AggingReportComponent implements OnInit {

  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: GAggingReportViewmodel = new GAggingReportViewmodel();
  public vmListItems: any = [];
  p: number = 1;
  public component_Title = 'Agging Report';

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.itemVm = new GAggingReportViewmodel();
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.reportingservice.GetList().subscribe((res: any) => {
      this.vmListItems = res;
      this.itemVm = res.DtlList;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();

    });
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.itemService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }

}
