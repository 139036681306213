import { Component, OnInit, TemplateRef } from '@angular/core';
import { ItemStockReportViewModel } from 'src/app/viewmodels/g-item/ItemStockReportViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockReportTotalViewModel } from 'src/app/viewmodels/g-item/StockReportTotalViewModel';

@Component({
  selector: 'app-itemstockreport',
  templateUrl: './itemstockreport.component.html',
  styleUrls: ['./itemstockreport.component.scss']
})
export class ItemstockreportComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: ItemStockReportViewModel = new ItemStockReportViewModel();
  public stockTotal: StockReportTotalViewModel = new StockReportTotalViewModel();
  public vmListItems: any = [];
  p: number = 1;
  public component_Title = 'Item Stock Report';

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.itemVm = new ItemStockReportViewModel();
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.itemService.GetItemReport().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      this.stockTotal = res;
      console.log(res);
      console.log(this.itemVm);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.itemService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }


}
