import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { G_ItemTypeViewModel } from 'src/app/viewmodels/g-settings/G_ItemTypeViewModel';

@Component({
  selector: 'app-item-type',
  templateUrl: './item-type.component.html',
  styleUrls: ['./item-type.component.scss']
})
export class ItemTypeComponent implements OnInit {

  public modalRef: BsModalRef;
  public itemVm: G_ItemTypeViewModel = new G_ItemTypeViewModel();
  public vmListItems: any = [];
  public component_Title = 'Item Type';

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.itemTypeService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      console.log(res);

      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new G_ItemTypeViewModel();
    }
  }
  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new G_ItemTypeViewModel();
      this.getList();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.itemTypeService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

}
