import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UnitOfWorkService } from "src/app/services/api/uow/unit-of-work.service";
import { JqHelper } from "src/app/common/jq-helper";
import { G_SaleMasterViewModel } from "src/app/viewmodels/g-sale/G_SaleMasterViewModel";
import { ItemViewModel } from "src/app/viewmodels/ItemViewModel";
import { GItemDefiningViewModel } from "src/app/viewmodels/g-item/GItemDefiningViewModel";
import { GVechileViewModel } from "src/app/viewmodels/g-vehicle/GVechileViewModel";
import { G_PurchaseMasterViewModel } from "src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel";
import { PurchaseDetailViewModel } from "src/app/viewmodels/g-purchase/PurchaseDetailViewModel";
import { GPackagesViewModel } from "src/app/viewmodels/g-packages/GPackagesViewModel";
import { GPackageItemViewModel } from "src/app/viewmodels/g-packages/GPackageItemViewModel";
import { GPackagesModelViewModel } from "src/app/viewmodels/g-packages/GPackagesModelViewModel";
import { ActivatedRoute, Router } from "@angular/router";
import { G_ComplainsSuggestionsViewModel } from "src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel";
import { DefineAccountviewModel } from "src/app/viewmodels/g-customer/DefineAccountviewModel";
import { G_TagsViewModel } from "src/app/viewmodels/g-settings/G_TagsViewModel";
import { SaleDetailViewModel } from "src/app/viewmodels/g-sale/SaleDetailViewModel";
import { G_PackagesTypeViewModel } from "src/app/viewmodels/g-packages/G_PackagesTypeViewModel";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import Swal from "sweetalert2/dist/sweetalert2.all.js";
import { timer } from "rxjs";
import { E_G_EPSStatusType } from "src/app/viewmodels/g-purchase/E_G_EPSStatusType";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-work-in-progess-form",
  templateUrl: "./work-in-progess-form.component.html",
  styleUrls: ["./work-in-progess-form.component.scss"],
})
export class WorkInProgessFormComponent implements OnInit {
  disabled = true;

  public vmListItems: any = [];
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public component_Title = "Sale";
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: "complaintsType";
  public packageTypes: any = [];
  public cvehicleDetail = "vehicleDetail";
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public saleDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = "packageTypes";
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = "items";
  public cbrands: any = "brands";
  public cmodels: any = "models";
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public saleDetail: any[];
  public selectedServicesList: any = [];
  public selectedSaprePartsList: any = [];
  public itemTypes = [];
  public csaleDetail: any[];
  public DisableSave: boolean = false;
  public userAssignedWareHouses: Array<any> = [];
  public userWareHouses: Array<any> = [];
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 6,
    allowSearchFilter: true,
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 6,
    allowSearchFilter: true,
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 6,
    allowSearchFilter: true,
  };
  public PackageSelectionDropdown: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };

  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "RegistraionNumber",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 6,
    allowSearchFilter: true,
  };
  modalRef: BsModalRef;
  public calingUrl: string;
  public urlId: number;
  findingsType: any;
  submitted: boolean;
  selectedTagsList: any;
  selectedComplaint: any;
  selectedFindings: any;
  public SelectedItemServices: any = [];
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    debugger;
    this.getSpareParts();
    this.getSparePartsAndServices();
    this.getPackageTypes();
    this.getTags();
    if (this.modal_title == undefined) {
      this.modal_title = "Work in Progress";
    }
    this.vm = new G_SaleMasterViewModel();
    this.saleDetailVM = new SaleDetailViewModel();
    //console.log(this.vm);
    this.route.queryParams.subscribe((params) => {
      this.urlId = params["id"];
    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService.GetById(this.urlId).subscribe(
      (res: any) => {
        this.spinner.hide();
        let selectedWarehouse:any=[];
        console.log(res);
        this.vm = res;
        this.vm.Fk_G_WareHouse_Id =new ItemViewModel(res.Fk_G_WareHouse_Id.Id, res.Fk_G_WareHouse_Id.Name);
        if(this.vm.Fk_G_WareHouse_Id !== undefined){
          selectedWarehouse.push({FK_WareHouse_Id:this.vm.Fk_G_WareHouse_Id});
          this.userAssignedWareHouses = selectedWarehouse;
        }
        // this._jq.showSpinner();
        //this.spinner.hide();
        let selectedServicesList: any = [];
        let selectedSaprePartsList: any = [];
        this.vm.SaleDetails.forEach((x) => {
          if (x.FK_G_Item_Type_Id.Id === 18) {
            let ab = new GPackageItemViewModel();
            ab.Quantity = x.Quantity;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(
              x.FK_G_Item_Id.Id,
              x.FK_G_Item_Id.Name
            );
            ab.DescountPercentage = x.Discount;
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.TotalAmount;
            ab.DiscountAmount = 0;
            ab.DiscountAmount = x.DiscountAmount;
            this.vmP.SpareParts.push(ab);
            let partssSelected = new GItemDefiningViewModel();
            partssSelected.Id = x.FK_G_Item_Id.Id;
            partssSelected.Name = x.FK_G_Item_Id.Name;
            selectedSaprePartsList.push(partssSelected);
            //this.InventoryCheck(x.FK_G_Item_Id.Id);
            this.discountChange(ab, 0);
          }

          if (x.FK_G_Item_Type_Id.Id === 19) {
            let ab = new GPackageItemViewModel();
            ab.Quantity = x.Quantity;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(
              x.FK_G_Item_Id.Id,
              x.FK_G_Item_Id.Name
            );
            ab.DescountPercentage = x.Discount;
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.TotalAmount;
            ab.DiscountAmount = 0;
            ab.DiscountAmount = x.DiscountAmount;
            this.vmP.Services.push(ab);
            let serviceslect = new GItemDefiningViewModel();
            serviceslect.Id = x.FK_G_Item_Id.Id;
            serviceslect.Name = x.FK_G_Item_Id.Name;
            selectedServicesList.push(serviceslect);
            this.discountChange(ab, 0);
          }
        });
        this.selectedSpareParts = selectedSaprePartsList;
        this.SelectedItemServices = selectedServicesList;
        console.log(res);
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
        // setTimeout(() => {
        //   /** spinner ends after 5 seconds */
        //   this.spinner.hide();
        // }, 5000);
      }
    );
    if (this.vmD == undefined) {
      this.vmD = new DefineAccountviewModel();
      this._uow.accountsService.GetCode().subscribe(
        (res: any) => {
          this.vmD.Acc_Code = res.code;
        },
        (err) => console.log(err)
      );
    }
    if (this.vmP == undefined) {
      this.vmP = new GPackagesViewModel();
      this.vmP.Services = [];
      this.vmP.SpareParts = [];
      this.selectedPacakgeTypes = [];
    } else {
      if (this.vmP.Services !== undefined && this.vmP.Services.length > 0) {
        this.vmP.Services.forEach((x) => {
          this.selectedServices.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
      if (this.vmP.SpareParts !== undefined && this.vmP.SpareParts.length > 0) {
        this.vmP.SpareParts.forEach((x) => {
          this.selectedSpareParts.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
      if (
        this.vmP.GPackagesModel !== undefined &&
        this.vmP.GPackagesModel.length > 0
      ) {
        this.vmP.GPackagesModel.forEach((x) => {
          this.selectedPacakgeTypes.push(
            new ItemViewModel(x.Fk_G_Pacakage_Id.Id, x.Fk_G_Pacakage_Id.Name)
          );
        });
      }
    }
    if (
      this.vm.SalesTagsList !== undefined &&
      this.vm.SalesTagsList.length > 0
    ) {
      this.vm.SalesTagsList.forEach((x) => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
      this.vm.SaleDetails.forEach((x) => {
        this.saleDetail.push(
          new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name)
        );
      });
    }

    // this.itemVm = new GVechileViewModel();
    // let id = this.route.snapshot.params['id'];
    // this._uow.vehicleService.GetById(id).subscribe((res: any)=>{
    //   this.itemVm = res;
    //   this.itemVm.FK_G_Model = new ItemViewModel(res.FK_G_Model.Id,res.FK_G_Model.Name);
    //   this.itemVm.Fk_G_Customer_Id = new ItemViewModel(res.Fk_G_Customer_Id.Id,res.Fk_G_Customer_Id.Name);
    //   this.itemVm.FK_TagId = new ItemViewModel(res.FK_TagId.Id, res.FK_TagId.Name);
    //  // this.vm.FK_ClientId = new ItemViewModel(res.FK_ClientId.Id, res.FK_ClientId.Name);
    //   console.log(res);
    // },(err)=>{
    //   console.log(err);
    // })
    // if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
    //   this.itemVm.FK_Tag_List.forEach(x => {
    //     this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
    //   });
    // }
    // if (this.vm == undefined) {
    //   this.vm = new G_SaleMasterViewModel();
    //   this.saleDetail = [];
    //   this.selectedsaleDetail = [];
    // } else {

    // }
    this.getUserAssignedWareHouse().then((res) => {
      this.getSpareParts(res[0].res[0].FK_WareHouse_Id.Id);
    });
    this.DisableSave = true;
    // setTimeout(() => {
      //<<<---    using ()=> syntax
      this.InventoryCheckAll();
    // }, 1000);
  }
  getUserAssignedWareHouse() {
    return new Promise((resolve, reject) => {
      this._uow.warehouseService.GetListByUserId().subscribe((res: any) => {
        console.log("ware house", res);
        this.userAssignedWareHouses = res;
       this.userWareHouses = res;
        resolve(res);
      });
    });
  }
  onSelectWareHouse() {
    this.getSpareParts(this.vm.Fk_G_WareHouse_Id.Id);
  }
  selectButton() {
    let obj = new G_SaleMasterViewModel();
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleQout);
    if (E_G_EPSStatusType.SaleQout) {
      let element_open = document.getElementById("open");
      element_open.classList.remove("btn-dark");
      let element_openDown = document.getElementById("openDown");
      element_openDown.classList.remove("btn-dark");
      this.DisableSave = true;
    }
    if (E_G_EPSStatusType.SaleWIP) {
      let element_wip = document.getElementById("wip");
      element_wip.classList.add("btn-dark");
      let element_wipDown = document.getElementById("wipDown");
      element_wipDown.classList.add("btn-dark");
      let element_btn_readyDown = document.getElementById("btn_readyDown");
      element_btn_readyDown.classList.remove("disabled");
      this.DisableSave = true;
    }
    if (E_G_EPSStatusType.SaleReady) {
      let element_btn_ready = document.getElementById("btn_ready");
      element_btn_ready.classList.remove("btn-dark");
      this.DisableSave = false;
    }
  }

  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    let obj = this.itemVm;
    
    this.vm.Fk_G_StatusType_Id = new ItemViewModel(6, "SaleWIP");
    this.vm.Inv_Amount = this.vmP.PackageAmount;
    this.vm.PaidAmount = this.vm.Inv_Amount;
    console.log(this.vm);
    this._uow.saleService
      .Put(this.vm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
        // setTimeout(() => {
        //   /** spinner ends after 5 seconds */
        //   this.spinner.hide();
        // }, 3000);
        this.spinner.hide();
        Swal.fire("Saved!", "Repair Order Updated!", "success");
        //this.bsmRef.hide();
        //alert("Repair Order Saved Successfully");
        //this._router.navigate(['/repair-order-list'])

        //this._document.defaultView.location.reload();
        this.bsmRef.hide();
        // this._jq.showSpinner();
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire("Error!", "Repair Order Not Updated!", "warning");
        this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
        // this._jq.showSpinner();
        // setTimeout(() => {
        //   /** spinner ends after 5 seconds */
        //   this.spinner.hide();
        // }, 3000);
      });
  }

  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }

  getSparePartsAndServices() {
    //'Spare-Parts'
    this._uow.itemService.GetSSItemDefiningByItemType("Spare-Parts").subscribe(
      (res: any) => {
        // this._uow.logger.log(res);
        this.spareParts = res;
        // this._uow.logger.log(`spare parts ${JSON.stringify(res)}`);
      },
      (err) => {
        this._uow.logger.log(err);
        this.spareParts = [];
      }
    );
    // 'Services'
    this._uow.itemService.GetSSItemDefiningByItemType("Services").subscribe(
      (res: any) => {
        this.services = res;
        // this._uow.logger.log(`services ${res}`);
      },
      (err) => {
        this._uow.logger.log(err);
        this.services = [];
        this._jq.showSpinner();
      }
    );
  }

  getPackageTypes() {
    this._uow.packageService.GetList().subscribe(
      (res: any) => {
        this.packageTypes = res;
        this._uow.logger.log(res);
      },
      (error) => {
        this._uow.logger.log(error);
        this.packageTypes = [];
        this._jq.showSpinner();
      }
    );
  }

  quantityChange(e, i?) {
    //e.DiscountAmount = 0;
    e.Amount = e.Quantity * e.DiscountAmount;
    let saledetail = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledetail != undefined) {
      saledetail.Quantity = e.Quantity;
      saledetail.TotalAmount = e.Amount;
    }
    //  if (e.DescountPercentage > 0) {
    //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
    //  }
    this.calculateTotals();
    this.InventoryCheckAll();
    //this.InventoryCheck(e.Fk_G_SSItemDefining_Id.Id);
  }

  discountChange(e, i?) {
    let discountAMmount = (e.DescountPercentage * e.SalePrice) / 100;
    e.DescountAmmount = discountAMmount;
    e.DiscountAmount = e.SalePrice - e.DescountAmmount;
    e.Amount = e.DiscountAmount * e.Quantity;

    let saledtl = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledtl != undefined) {
      saledtl.DiscountAmount = e.DescountAmmount;
      saledtl.Discount = e.DescountPercentage;
      saledtl.DiscountedRate = e.DiscountAmount;
      saledtl.TotalAmount = e.Amount;
    }
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount <= 0){
    //   let discountAMmount = ((e.DescountPercentage / 100) * e.Amount);
    //   e.Amount = (e.Amount - discountAMmount);
    //   this.calculateTotals();
    // }
    this.calculateTotals();
  }

  amountChange(e, i?) {
    e.DescountPercentage = (e.DescountAmmount * 100) / e.SalePrice;
    this.discountChange(e, i);
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount < 0){
    //   let discountAMmount = (e.Amount-e.DescountAmmount);
    //   e.Amount = (discountAMmount);

    // }
    this.calculateTotals();
  }

  // spare parts
  // spare parts
  onSelectSparePart(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);

    let item = this.spareParts.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = 0;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount = item.SalePrice;
      if (this.vm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.vm.Fk_G_Customer_Discount;
      }
      this.vmP.SpareParts.push(a);
      this.discountChange(this.vmP.SpareParts[this.vmP.SpareParts.indexOf(a)]);
    }
    this.InventoryCheck(e.Id);
    this.calculateTotals();
  }

  onDeSelectSparePart(e) {
    this.selectedSpareParts = [];
    let selectedSaprePartsList: any = [];
    this.userAssignedWareHouses = this.userWareHouses;
    let i = this.vmP.SpareParts.find(
      (x) => x.Fk_G_SSItemDefining_Id.Id === e.Id
    );
    if (i !== undefined) {
      this.vmP.SpareParts.splice(this.vmP.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
    this.InventoryCheckAll();
  }

  RemoveSparePart(e) {
    this.selectedSpareParts = [];
    let selectedSaprePartsList: any = [];
    let i = this.vmP.SpareParts.find(
      (x) => x.Fk_G_SSItemDefining_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (i !== undefined) {
      this.vmP.SpareParts.splice(this.vmP.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.vmP.SpareParts.forEach((x) => {
      let partssSelected = new GItemDefiningViewModel();
      partssSelected.Id = x.Fk_G_SSItemDefining_Id.Id;
      partssSelected.Name = x.Fk_G_SSItemDefining_Id.Name;
      selectedSaprePartsList.push(partssSelected);
    });
    this.selectedSpareParts = selectedSaprePartsList;
    this.calculateTotals();
    this.InventoryCheckAll();
  }
  //service
  onSelectService(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);
    let item = this.services.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      //i.AvalibelQuantity = item.CurrentStock;
      i.Item_Code = item.Code;
      i.DiscountedRate = 0;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      if (this.vm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.vm.Fk_G_Customer_Discount;
      }
      this.vmP.Services.push(a);
      this.discountChange(this.vmP.SpareParts[this.vmP.SpareParts.indexOf(a)]);
    }

    this.calculateTotals();
  }

  onDeSelectService(e) {
    this.selectedServices = [];
    let selectedServicesList: any = [];
    let i = this.vmP.Services.find((x) => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmP.Services.splice(this.vmP.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  RemoveService(e) {
    this.SelectedItemServices = [];
    let selectedServicesList: any = [];
    let i = this.vmP.Services.find(
      (x) => x.Fk_G_SSItemDefining_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (i !== undefined) {
      this.vmP.Services.splice(this.vmP.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.vmP.Services.forEach((x) => {
      let partssSelected = new GItemDefiningViewModel();
      partssSelected.Id = x.Fk_G_SSItemDefining_Id.Id;
      partssSelected.Name = x.Fk_G_SSItemDefining_Id.Name;
      selectedServicesList.push(partssSelected);
    });
    this.SelectedItemServices = selectedServicesList;
    this.calculateTotals();
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe(
      (res: any) => {
        this.tags = res;
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  // Package
  onSelectPackage(e, i?) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }

    // let iii = document.getElementById(this.spareParts[0].Id);
    this._uow.logger.log(e);
    let item = this.packageTypes.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let selectedServicesList: any = [];
      let selectedSaprePartsList: any = [];
      if (this.vm.SaleDetails != undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach((x) => {
          if (x.Item_Type === "Services") {
            let serviceslect = new GItemDefiningViewModel();
            serviceslect.Id = x.FK_G_Item_Id.Id;
            serviceslect.Name = x.FK_G_Item_Id.Name;
            selectedServicesList.push(serviceslect);
          }
          if (x.Item_Type === "Spare-Parts") {
            let partssSelected = new GItemDefiningViewModel();
            partssSelected.Id = x.FK_G_Item_Id.Id;
            partssSelected.Name = x.FK_G_Item_Id.Name;
            selectedSaprePartsList.push(partssSelected);
          }
        });
        //this.selectedSpareParts = selectedSaprePartsList;
        //this.SelectedItemServices = selectedServicesList;
      }

      item.GPackagesItem.forEach((x) => {
        let alreadyExisting = this.vm.SaleDetails.find(
          (z) => z.FK_G_Item_Id.Id === x.Fk_G_SSItemDefining_Id.Id
        );
        if (alreadyExisting == undefined || alreadyExisting == null) {
          if (x.FK_G_ItemType_Id.Id === 18) {
            let selectedparts = this.spareParts.find(
              (y) => y.Id === x.Fk_G_SSItemDefining_Id.Id
            );
            //if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = 0;
            i.Quantity = x.Quantity;
            i.FK_G_Item_Id = new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            );
            i.Item_Code = x.Code;
            i.Item_Name = x.Fk_G_SSItemDefining_Id.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.TotalAmount = x.Amount;
            i.Rate = x.SalePrice;
            i.DiscountedRate = x.SalePrice;
            i.Item_Type = x.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            i.Discount = 0;
            i.DiscountAmount = 0;
            this.vm.SaleDetails.push(i);
            let ab = new GPackageItemViewModel();
            ab.Id = 0;
            ab.Quantity = x.Quantity;
            ab.DescountPercentage = 0;
            ab.DescountPercentage = x.DescountPercentage;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            );
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.Amount;
            ab.DiscountAmount = 0;
            if (this.itemVm.Fk_G_Customer_Discount > 0) {
              ab.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
            }
            this.vmP.SpareParts.push(ab);
            this.discountChange(ab, i);

            let partssSelected = new GItemDefiningViewModel();
            partssSelected.Id = x.Fk_G_SSItemDefining_Id.Id;
            partssSelected.Name = x.Fk_G_SSItemDefining_Id.Name;
            selectedSaprePartsList.push(partssSelected);
            //}
          }

          if (x.FK_G_ItemType_Id.Id === 19) {
            let selectedservices = this.services.find(
              (y) => y.Id === x.Fk_G_SSItemDefining_Id.Id
            );
            //if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = 0;
            i.Quantity = x.Quantity;
            i.FK_G_Item_Id = new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            );
            i.Item_Code = x.Code;
            i.Item_Name = x.Fk_G_SSItemDefining_Id.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.TotalAmount = x.Amount;
            i.Rate = x.SalePrice;
            i.DiscountedRate = x.SalePrice;
            i.Item_Type = x.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            i.Discount = 0;
            i.DiscountAmount = 0;
            this.vm.SaleDetails.push(i);
            let ab = new GPackageItemViewModel();
            ab.Id = 0;
            ab.Quantity = x.Quantity;
            ab.DescountPercentage = 0;
            ab.DescountPercentage = x.DescountPercentage;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            );
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.Amount;
            ab.DiscountAmount = 0;
            if (this.itemVm.Fk_G_Customer_Discount > 0) {
              ab.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
            }
            this.vmP.Services.push(ab);
            //this.selectedServices.push(a);

            let serviceslect = new GItemDefiningViewModel();
            serviceslect.Id = x.Fk_G_SSItemDefining_Id.Id;
            serviceslect.Name = x.Fk_G_SSItemDefining_Id.Name;
            selectedServicesList.push(serviceslect);
            this.discountChange(ab, i);
            //}
          }
        }
      });
      this.selectedSpareParts = selectedSaprePartsList;
      this.SelectedItemServices = selectedServicesList;
    }
    console.log(e);
  }

  onDeSelectPackage(e) {
    this._uow.logger.log(e);
    let item = this.packageTypes.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      item.GPackagesItem.forEach((y) => {
        let c = this.vmP.SpareParts.find(
          (x) => x.Fk_G_SSItemDefining_Id.Id === y.Fk_G_SSItemDefining_Id.Id
        );
        if (c !== undefined) {
          this.vmP.SpareParts.splice(this.vmP.SpareParts.indexOf(c), 1);
        }

        let a = this.vmP.Services.find(
          (x) => x.Fk_G_SSItemDefining_Id.Id === y.Fk_G_SSItemDefining_Id.Id
        );
        if (a !== undefined) {
          this.vmP.Services.splice(this.vmP.Services.indexOf(a), 1);
        }

        let d = this.vm.SaleDetails.find(
          (x) => x.FK_G_Item_Id.Id === y.Fk_G_SSItemDefining_Id.Id
        );
        if (d !== undefined) {
          this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
        }
        if (y.FK_G_ItemType_Id.Id === 18) {
        }

        if (y.FK_G_ItemType_Id.Id === 19) {
        }
        this.calculateTotals();
      });
    }
  }

  // Tags
  onSelectTags(e) {
    if (
      this.vm.SalesTagsList == undefined ||
      this.vm.SalesTagsList.length == 0
    ) {
      this.vm.SalesTagsList = [];
    }

    this._uow.logger.log(e);
    let item = this.tags.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTagsList.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);
    }
  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTagsList.find((x) => x.FK_Tag_Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTagsList.splice(this.vm.SalesTagsList.indexOf(i), 1);
    }
  }

  InventoryCheck(e) {
    let SparepartsEvent = this.spareParts.find((y) => y.Id === e);
    if (SparepartsEvent != undefined && SparepartsEvent != null) {
      let AlreadySelectedParts = this.vmP.SpareParts.find(
        (y) => y.Fk_G_SSItemDefining_Id.Id === e
      );
      if (AlreadySelectedParts != undefined && AlreadySelectedParts != null) {
        let AlreadyPartsIndex = this.vmP.SpareParts.indexOf(
          AlreadySelectedParts
        );
        if (AlreadySelectedParts.Quantity > SparepartsEvent.CurrentStock) {
          if (AlreadyPartsIndex != undefined) {
            this.vmP.SpareParts[AlreadyPartsIndex].IsInventry = false;
          }
          this.DisableSave = true;
        } else {
          if (AlreadyPartsIndex != undefined) {
            this.vmP.SpareParts[AlreadyPartsIndex].IsInventry = true;
          }
          AlreadySelectedParts.IsInventry = true;
          this.DisableSave = false;
        }
      }
    }
  }

  InventoryCheckAll() {
    debugger;
    if (this.vmP.SpareParts != undefined && this.vmP.SpareParts.length > 0) {
      this.vmP.SpareParts.forEach((x) => {
        let SparepartsEvent = this.spareParts.find(
          (y) => y.Id === x.Fk_G_SSItemDefining_Id.Id
        );
        if (SparepartsEvent != undefined && SparepartsEvent != null) {
          if (x.Quantity > SparepartsEvent.CurrentStock) {
            x.IsInventry = false;
            this.DisableSave = true;
          } else {
            x.IsInventry = true;
            this.DisableSave = false;
          }
        }
      });
    } else {
      this.DisableSave = false;
    }
  }

  calculateTotals() {
    this.vmP.DiscountAmount = 0;
    this.vmP.ServiceAmount = 0;
    this.vmP.Services.forEach((x) => {
      this.vmP.ServiceAmount += x.Amount;
      if (x.DescountAmmount > 0) {
        this.vmP.DiscountAmount += x.DescountAmmount * x.Quantity;
      }
    });

    this.vmP.PartsAmount = 0;
    this.vmP.SpareParts.forEach((x) => {
      this.vmP.PartsAmount += x.Amount;
      if (x.DescountAmmount > 0) {
        this.vmP.DiscountAmount += x.DescountAmmount * x.Quantity;
      }
    });

    // this.vmP.SpareParts.forEach(x => {
    //  this.vmP.PartsAmount += x.Amount;
    //   if (x.DescountAmmount > 0) {
    //     //this.vmP.DiscountAmount += (x.Amount - x.DescountAmmount);
    //     this.vmP.DiscountAmount = x.Amount-x.DescountAmmount;
    //     this.vmP.DiscountAmount = x.DescountAmmount;
    //   }
    // });

    // this.vmP.Services.forEach(x => {
    //   //this.vmP.ServiceAmount += x.Amount;
    //   if (x.DescountAmmount > 0) {
    //     this.vmP.DiscountAmount = x.Amount-x.DescountAmmount;
    //   }
    // });

    this.vmP.PackageAmount = this.vmP.PartsAmount + this.vmP.ServiceAmount;
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find((x) => x.Name == "Spare-Parts");
      console.log(i);
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find((x) => x.Name == "Services");
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass("modal-lg");
  }

  getRegNo() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        console.log(res);
        this._jq.showSpinner();
        this.vm.Fk_G_Vechile_Id = res.code;
      },
      (error) => {
        console.log(error);
        this._jq.showSpinner();
      }
    );
  }

  //'Spare-Parts'
  getSpareParts(id?) {
    let selectedSpare: any = [];
    this._jq.showSpinner(true);
    this._uow.itemService
      .GetSSItemDefiningByItemType("Spare-Parts", id)
      .subscribe(
        (res: any) => {
          this.spareParts = res;
          this.InventoryCheckAll();
          console.log(res);
          if (this.selectedSpareParts.length > 0) {
            console.log(this.selectedSpareParts);
            this.selectedSpareParts.forEach((x) => {
              console.log(this.vmP.SpareParts);
              let selected = this.vmP.SpareParts.find(
                (z) => z.Fk_G_SSItemDefining_Id.Id === x.Id
              );
              console.log("selcetd part", selected);
              let md = new ItemViewModel();
              (md.Id = selected.Fk_G_SSItemDefining_Id.Id),
                (md.Name = selected.Fk_G_SSItemDefining_Id.Name);
              selectedSpare.push(md);
              this.selectedSpareParts = selectedSpare;
            });
          }
          this._jq.showSpinner();
        },
        (err) => {
          this._uow.logger.log(err);
          this.spareParts = [];
          this._jq.showSpinner();
        }
      );
  }

  getRequisitions() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        console.log(res.DtlList);
        this.vehicleDetail = res.RegistraionNumber;
        this._jq.showSpinner();
      },
      (error) => {
        console.log(error);
        this._jq.showSpinner();
      }
    );
  }

  getVehicleDetail() {
    //'Spare-Parts'
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetById("Vehicle-Detail").subscribe(
      (res: any) => {
        this.vehicleDetail = res;
        console.log(res);
        this._jq.showSpinner();
      },
      (err) => {
        this._uow.logger.log(err);
        this.vehicleDetail = [];
        this._jq.showSpinner();
      }
    );
  }

  openModal1(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    //  if (lvl == 2) {
    //    this.modalRefLv2 = this._modalService.show(template);
    //  }
  }

  getSortIndexList(sIndex) {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetPagedResult(0, 50, sIndex).subscribe(
      (res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);

        this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.vm = new G_SaleMasterViewModel();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.saleService.Delete(this.vm).subscribe(
      (res: any) => {
        this.getList();
        //this.modalRefLv1.hide();
      },
      (err) => {
        this._jq.showSpinner();
      }
    );
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  // input changes
  quantityOrPriceChanged(m) {
    m.TotalAmount = m.Quantity * m.PurchasePrice;
    this.updateTotals();
  }

  public TotalPayable = 0;
  public TotalVat = 0;
  public TotalPayableAfterVat = 0;
  updateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    if (
      this.itemVm.SaleDetails !== undefined &&
      this.itemVm.SaleDetails.length > 0
    ) {
      this.itemVm.SaleDetails.forEach((x) => {
        this.TotalPayable += x.TotalAmount;
        this.TotalVat += x.VatAmount;
        this.TotalPayableAfterVat += x.TotalAmount + x.VatAmount;
      });
    }
  }
}
