import { ItemViewModel } from '../ItemViewModel';

export class GPackagesModelViewModel {
    public Id: number;
    public Fk_G_Brands: ItemViewModel;
    public Fk_G_Models: ItemViewModel;
    public Fk_G_Pacakage_Id: ItemViewModel;
    // public FK_ClientId: ItemViewModel;
    public IsService: boolean;
    // public IsActive: boolean;
}
