import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { IHttpService } from '../../common/IHttpService';
import { G_Warehouse_Viewmodel } from 'src/app/viewmodels/g-settings/G_Warehouse_Viewmodel';
import { WareHouseItemViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';

@Injectable({
  providedIn: 'root'
})
export class WareHouseService implements IHttpService<G_Warehouse_Viewmodel> {
  private _ep: EndpointConfig;
  // private _wp:EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
    // this._wp= new EndpointConfig('GSSItem');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_WareHouseList'));
  }
  GetListByUserId() {
    return this._client.post(this._ep.getEndpointMethod('GetG_Users_WareHousesListByUserId'),{});
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_WareHouseDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_WareHouseById'), { Id: id });
  }
  Post(obj: G_Warehouse_Viewmodel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_WareHouse'), obj);
  }

  Put(obj: G_Warehouse_Viewmodel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_WareHouse'), obj);
  }

  Delete(obj: G_Warehouse_Viewmodel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_WareHouse'), obj);
  }

}
