import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GSalesReportByDate } from 'src/app/viewmodels/g-settings/GSalesReportByDate';

@Component({
  selector: 'app-sales-reports-by-date',
  templateUrl: './sales-reports-by-date.component.html',
  styleUrls: ['./sales-reports-by-date.component.scss']
})
export class SalesReportsByDateComponent implements OnInit {
  public component_Title = 'Sales Report';
  public SalesReportByDate = new GSalesReportByDate();

  constructor(    private _uow: UnitOfWorkService, private spinner : NgxSpinnerService,
  ) {
    // this.SalesReportByDate.StartDate = new Date();
    // this.SalesReportByDate.EndDate = new Date();
   }

  ngOnInit() {
  }

  downloadFile(fileUrl: string) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'SalesReport.xlsx'; // Specify the file name

    // Append the anchor to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Remove the anchor from the body
    document.body.removeChild(link);
  }

  getDates(){
    if(this.SalesReportByDate.StartDate > this.SalesReportByDate.EndDate){
      console.log("date error");
    }else{
      this.spinner.show();
      this._uow.salesreportservice.GetSalesReport(this.SalesReportByDate).subscribe((res: any) => {
        this.spinner.hide();
        console.log("fetch list", res);
      if (res) {
        window.open(res.Name,'_blank');
      }
      }, err => {
        this.spinner.hide();
      })
    }
  }

}
