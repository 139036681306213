import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bulk-payments',
  templateUrl: './bulk-payments.component.html',
  styleUrls: ['./bulk-payments.component.scss']
})
export class BulkPaymentsComponent implements OnInit {
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  @Input() public modal_title: any;
  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public vmS: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public vmListItem: any = [];
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  public vmI : G_Installment_View_Model =  new G_Installment_View_Model();
  @Input() public bulkInstallment : G_Bulk_Installment_View_Model;
  public IsDisable:boolean = false;
  p: number = 1;
  dataTable: any;
  public customerDetail: any = [];
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Bulk Payments';
  public isEdit= false;
  public isDisabled = false;
  public refid: number;
  public total: number;
  public paidamount: number;
  public balance: number;
  public calingUrl: string;
  public urlId: number;
  public isPreview: boolean = false;
  private submitted = false;
  public suppliers: any = [];
  public selectedCustomer: any = [];
  public accountSelected : any = [];
  public accountList : ItemViewModel[] = [];

  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
    limitSelection: 1
  };

  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
    limitSelection: 1
  };
  public nowDate: Date;

  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.nowDate = new Date();
    debugger
    if (this.vmI == undefined) {
      this.vmI = new G_Installment_View_Model();
      this.vmI.TransactionDate = new Date();
      this.vmI.G_FK_Sale_Id = new ItemViewModel(0,'');    }
    if (this.bulkInstallment == undefined) {
      this.bulkInstallment = new G_Bulk_Installment_View_Model();
      this.bulkInstallment.TransactionDate = new Date();
      this.bulkInstallment.FK_Client_Id = new ItemViewModel;
      this.bulkInstallment.FK_Client_Id.Id = 0;
      this.bulkInstallment.FK_Client_Id.Name = "";
      this.bulkInstallment.FK_G_CashAndBank_Id = new ItemViewModel;
      this.bulkInstallment.FK_G_CashAndBank_Id.Id = 0;
      this.bulkInstallment.FK_G_CashAndBank_Id.Name = "";
      this.bulkInstallment.FK_G_Customer_Id = new ItemViewModel;
      this.bulkInstallment.FK_G_Customer_Id.Id = 0;
      this.bulkInstallment.FK_G_Customer_Id.Name = "";
      this.bulkInstallment.TotalBalance = 0;
      this.bulkInstallment.Remarks = "";
      this.bulkInstallment.TotalInvoiceAmount = 0;
      this.bulkInstallment.BulkInstallments =[];
      this.bulkInstallment.TotalPaidAmount = 0;
      this.bulkInstallment.TotalAmount = 0;
    }else{
      this.bulkInstallment.TransactionDate = new Date();
      this.isDisabled = true;
      this.calingUrl = this._router.url;
      this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];
      // this._jq.showSpinner(true);
      this.spinner.show();
      debugger
      this._uow.saleService.GetBulkById(this.urlId).subscribe((res: any) =>{
        this.bulkInstallment = res;
        this.bulkInstallment.TransactionDate = new Date(res.TransactionDate);
        console.log(res);
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 5000);
      // this._jq.showSpinner();
      })
    });
    this.bulkInstallment.TransactionDate = new Date(this.bulkInstallment.TransactionDate);
     let selectedCustomers : any = [];
     if (this.bulkInstallment.FK_G_Customer_Id != undefined && this.bulkInstallment.FK_G_Customer_Id != null) {
   let sCA = new DefineAccountviewModel();
   sCA.Id = this.bulkInstallment.FK_G_Customer_Id.Id;
   sCA.Acc_Titel = this.bulkInstallment.FK_G_Customer_Id.Name;
   selectedCustomers.push(sCA);
   this.selectedCustomer = selectedCustomers;
   this.bulkInstallment.FK_G_Customer_Id = new ItemViewModel(this.bulkInstallment.FK_G_Customer_Id.Id,this.bulkInstallment.FK_G_Customer_Id.Name);
   }
   let account : any = [];
   if (this.bulkInstallment.FK_G_CashAndBank_Id != undefined && this.bulkInstallment.FK_G_CashAndBank_Id != null) {
   let sCA = new DefineAccountviewModel();
   sCA.Id = this.bulkInstallment.FK_G_CashAndBank_Id.Id;
   sCA.Acc_Code = this.bulkInstallment.FK_G_CashAndBank_Id.Name;
   sCA.Name = this.bulkInstallment.FK_G_CashAndBank_Id.Name;;
   account.push(sCA);
   this.accountSelected = account;
   this.bulkInstallment.FK_G_CashAndBank_Id = new ItemViewModel(this.bulkInstallment.FK_G_CashAndBank_Id.Id,this.bulkInstallment.FK_G_CashAndBank_Id.Name);
   }
   this.bulkInstallment.FK_G_CashAndBank_Id.Name = this.bulkInstallment.FK_G_CashAndBank_Id.Name;
   this.bulkInstallment.TotalInvoiceAmount = this.bulkInstallment.TotalInvoiceAmount;
   this.bulkInstallment.TotalPaidAmount = this.bulkInstallment.TotalPaidAmount;
   this.bulkInstallment.TotalBalance = this.bulkInstallment.TotalBalance;
   this.bulkInstallment.TotalAmount = this.bulkInstallment.TotalAmount;
   this.bulkInstallment.Remarks = this.bulkInstallment.Remarks;
  }
    if (this.vmS == undefined) {
      this.vmS = new G_SaleMasterViewModel();
      this.vmS.TransactionDate = new Date();
      this.vmS.PaymentType = 1;
      this.vmS.SaleDetails = [];
      this.vmS.FK_Customer_Acc = new ItemViewModel();
      this.vmS.FK_Customer_Acc.Id = 0;
      this.vmS.FK_Customer_Acc.Name ="";
      this.vmS.PaidAmount = 0;
      this.vmS.Inv_Amount = 0;
    }
    this.getAccount(' ', 'CaB');
    this.getCustomers();

  }


getAccount(x: string, event: string) {
  this._jq.showSpinner(true);
debugger
  this._uow.accountsService.getAccountBySearch(x, event).subscribe((res: any) => {
    if (res) {
      this.accountList = res;
      console.log(res);
      this._jq.showSpinner();
    }
  }, err => {
    console.log(err);
  });
}



getCustomerName() {
  this._jq.showSpinner(true);
  debugger
  this._uow.customerService.GetList().subscribe((res: any) => {

    this.customerDetail = res.DtlList;
    // this.itemVm.RegistraionNumber = res;
    console.log(res);
    this._jq.showSpinner();
    // this.itemVm.RegistraionNumber = res.code;
  }, (error) => {
    this._uow.logger.log(error);
    this._jq.showSpinner();
  });
}

getCustomers() {

  let Customer = "Customer";
  this._uow.customerService.GetListSuplier(Customer).subscribe((res: any) => {
    // console.log(res);
    this.customerDetail = res.DtlList;
    console.log(this.customerDetail);
  }, (error) => {
    this._uow.logger.log(error);
    this.customerDetail = [];
  });
}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
  }

  openModalLg(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

  public acountData:ItemViewModel;
  onSelectSupplier(e) {
    let item = this.accountList.find(x => x.Id === e.Id);
    //  let i = new G_Installment_View_Model();
    //  i.G_Fk_DabitAccount_Id = new ItemViewModel();
    //  i.G_Fk_DabitAccount_Id.Id = item.Id;
    //  i.G_Fk_DabitAccount_Id.Name = item.Name;
    this.acountData = new ItemViewModel();
    this.acountData.Id = item.Id;
    this.acountData.Name = item.Name;

 }

 public customerData:ItemViewModel;
 onSelectCustomer(e) {
  debugger
   let item = this.customerDetail.find(x => x.Id === e.Id);
   //  let i = new G_Installment_View_Model();
   //  i.G_Fk_DabitAccount_Id = new ItemViewModel();
   //  i.G_Fk_DabitAccount_Id.Id = item.Id;
   //  i.G_Fk_DabitAccount_Id.Name = item.Name;
   this.customerData = new ItemViewModel();
   this.customerData.Id = item.Id;
   this.customerData.Name = item.Acc_Titel;
}

 getCustomerInvoice(){
   debugger
  //this.bulkInstallment = new G_Bulk_Installment_View_Model();
  var td = null;
  if(this.bulkInstallment.TransactionDate != null){
    td = this.bulkInstallment.TransactionDate.toDateString();
  }
  if(this.customerData!=null){
    this._uow.saleService.GetSaleInvoices(this.customerData.Id,td).subscribe((res: any) => {
      //this.vmListItems = res;
      this.bulkInstallment.BulkInstallments = res;

      this.InvoicesTotal();
      console.log(res);
    }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }
  if(this.bulkInstallment.FK_G_Customer_Id!=null){
    let id = this.bulkInstallment.FK_G_Customer_Id.Id;
    this._uow.saleService.GetSaleInvoices(id,td).subscribe((res: any) => {
      //this.vmListItems = res;
      this.bulkInstallment.BulkInstallments = res;
      debugger
      this.bulkInstallment.BulkInstallments.forEach(e => {
        let existingList = this.bulkInstallment.BulkInstallments.find(x => x.G_FK_Sale_Id.Id === e.G_FK_Sale_Id.Id);
        if(existingList == undefined){
          this.bulkInstallment.BulkInstallments=res;
        }
        else{
          //this.bulkInstallment.BulkInstallments = res;
        }
      });
      this.InvoicesTotal();
      console.log(res);
    }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }
 }

 InvoicesTotal(){
   debugger
   this.bulkInstallment.TotalInvoiceAmount = 0;
    this.bulkInstallment.TotalPaidAmount = 0;
    this.bulkInstallment.TotalBalance = 0;
    this.bulkInstallment.TotalAmount = 0;
   if(this.bulkInstallment.BulkInstallments.length > 0){
     this.bulkInstallment.BulkInstallments.forEach(element => {
         this.bulkInstallment.TotalInvoiceAmount += element.InvoiceAmount;
         this.bulkInstallment.TotalPaidAmount += element.PaidAmount;
         this.bulkInstallment.TotalBalance += element.RemainingAmount;
         this.bulkInstallment.TotalAmount += element.Amount;
         if (element.Amount > element.RemainingAmount) {
           element.IsDisable = true;
           this.IsDisable = true;
         }else{
          element.IsDisable = false;
          this.IsDisable = false;
         }
         let disblObject = this.bulkInstallment.BulkInstallments.find(x=> x.IsDisable == true);
         if (disblObject != undefined) {
          this.IsDisable = true;
         }else{
          this.IsDisable = false;
         }
      });
   }
 }

 amountChange(){
   debugger
   this.InvoicesTotal();
 }

 public attachfile(event) {
  this.vmI.file = event.target.files[0];
}

  saveOrUpdate() {
    debugger
    this.bulkInstallment.FK_Client_Id = new ItemViewModel();
    this.bulkInstallment.FK_Client_Id.Id = 0;
    this.bulkInstallment.FK_Client_Id.Name = "";
    if(this.bulkInstallment.FK_G_CashAndBank_Id.Id == 0){
      this.bulkInstallment.FK_G_CashAndBank_Id = new ItemViewModel();
      this.bulkInstallment.FK_G_CashAndBank_Id.Id = this.acountData.Id;
      this.bulkInstallment.FK_G_CashAndBank_Id.Name = this.acountData.Name;
    }else{
      this.accountSelected.Id == this.bulkInstallment.FK_G_CashAndBank_Id.Id;
      this.accountSelected.Name == this.bulkInstallment.FK_G_CashAndBank_Id.Name
      //this.bulkInstallment.FK_G_CashAndBank_Id.Id == this.accountSelected.Id;
      //this.bulkInstallment.FK_G_CashAndBank_Id.Name == this.accountSelected.Name;
    }
    if(this.bulkInstallment.FK_G_Customer_Id.Id == 0){
      this.bulkInstallment.FK_G_Customer_Id = new ItemViewModel();
      this.bulkInstallment.FK_G_Customer_Id.Id = this.customerData.Id;
      this.bulkInstallment.FK_G_Customer_Id.Name = this.customerData.Name;
    }else{
      this.selectedCustomer.Id == this.bulkInstallment.FK_G_Customer_Id.Id;
      this.selectedCustomer.Name == this.bulkInstallment.FK_G_Customer_Id.Name;
    }
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService.PostBulkPayment(this.bulkInstallment).toPromise().then((res: any) =>{
      this.submitted = true;
      this.onSubmitted.emit(this.submitted);
      this.bsmRef.hide();
      console.log(res);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Created',
        'Payment Paid',
        'success'
      )
    })
    .catch((error) =>{
      Swal.fire(
        'Failure',
        'Payment Not Paid',
        'error'
      )
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(false);
      this.spinner.hide();
      // this._jq.showSpinner();
    });
    // if (this.calingUrl.startsWith('/purchase')) {
    //   this.vmI.G_FK_Purchase_Id = new ItemViewModel();
    //   this.vmI.G_FK_Purchase_Id.Id = this.vm.Id;
    //   this.vmI.G_FK_Purchase_Id.Name = "";
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_DabitAccount_Id.Id = this.vm.FK_Sup_Cradit_Acc.Id;
    //   this.vmI.G_Fk_DabitAccount_Id.Name = this.vm.FK_Sup_Cradit_Acc.Name;
    //   this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_CreditAccount_Id.Id = this.acountData.Id;
    //   this.vmI.G_Fk_CreditAccount_Id.Name = this.acountData.Name;
    //   this._uow.purchaseService
    //     .PostInstallment(this.vmI)
    //     .toPromise().then((res: any) => {
    //       this.submitted = true;
    //       console.log(res);
    //       this.onSubmitted.emit(this.submitted);
    //       this.getLatestPurchaseData();
    //       Swal.fire(
    //         'Created!',
    //         'Installment Added/Updated!',
    //         'success'
    //       )
    //     })
    //     .catch((error) => {
    //       Swal.fire(
    //         'Failure!',
    //         'Insallment Not Added/Updated!',
    //         'error'
    //       )
    //       console.log(`Promise rejected with ${JSON.stringify(error)}`);
    //       this.onSubmitted.emit(false);
    //     });
    //   }
    // if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
    //   this.vmI.G_FK_Sale_Id = new ItemViewModel();
    //   this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
    //   this.vmI.G_FK_Sale_Id.Name = "";
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
    //   this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
    //   this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
    //   this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
    //   this._uow.purchaseService
    //     .PostInstallment(this.vmI)
    //     .toPromise().then((res: any) => {
    //       this.submitted = true;
    //       console.log(res);
    //       this.onSubmitted.emit(this.submitted);
    //       this.getLatestSaleData();
    //       this.getSaleList();

    //       Swal.fire(
    //         'Created!',
    //         'Installment Added/Updated!',
    //         'success'
    //       )
    //     })
    //     .catch((error) => {
    //       Swal.fire(
    //         'Failure!',
    //         'Insallment Not Added/Updated!',
    //         'error'
    //       )
    //       console.log(`Promise rejected with ${JSON.stringify(error)}`);
    //       this.onSubmitted.emit(false);
    //     });
    // }

    // if (this.calingUrl.startsWith('/direct-sale-list')) {
    //   this.vmI.G_FK_Sale_Id = new ItemViewModel();
    //   this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
    //   this.vmI.G_FK_Sale_Id.Name = "";
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
    //   this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
    //   this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
    //   this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
    //   this._uow.purchaseService
    //     .PostInstallment(this.vmI)
    //     .toPromise().then((res: any) => {
    //       this.submitted = true;
    //       console.log(res);
    //       this.onSubmitted.emit(this.submitted);
    //       this.getLatestSaleData();
    //       this.getSaleList();

    //       Swal.fire(
    //         'Created!',
    //         'Installment Added/Updated!',
    //         'success'
    //       )
    //     })
    //     .catch((error) => {
    //       Swal.fire(
    //         'Failure!',
    //         'Insallment Not Added/Updated!',
    //         'error'
    //       )
    //       console.log(`Promise rejected with ${JSON.stringify(error)}`);
    //       this.onSubmitted.emit(false);
    //     });
    // }

    // if (this.calingUrl.startsWith('/payment-status')) {
    //   this.vmI.G_FK_Sale_Id = new ItemViewModel();
    //   this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
    //   this.vmI.G_FK_Sale_Id.Name = "";
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
    //   this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
    //   this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
    //   this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
    //   this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
    //   this._uow.purchaseService
    //     .PostInstallment(this.vmI)
    //     .toPromise().then((res: any) => {
    //       this.submitted = true;
    //       console.log(res);
    //       this.onSubmitted.emit(this.submitted);
    //       this.getLatestSaleData();
    //       this.getSaleList();

    //       Swal.fire(
    //         'Created!',
    //         'Installment Added/Updated!',
    //         'success'
    //       )
    //     })
    //     .catch((error) => {
    //       Swal.fire(
    //         'Failure!',
    //         'Insallment Not Added/Updated!',
    //         'error'
    //       )
    //       console.log(`Promise rejected with ${JSON.stringify(error)}`);
    //       this.onSubmitted.emit(false);
    //     });
    // }

  }

  EditPurchaseInstallment(b) {
  this.bulkInstallment.TransactionDate = new Date(b.TransactionDate);
  this.bulkInstallment.FK_G_CashAndBank_Id = new ItemViewModel();
  this.bulkInstallment.FK_G_CashAndBank_Id.Id - this.acountData.Id;
  this.bulkInstallment.FK_G_CashAndBank_Id.Name = this.acountData.Name;
  this.bulkInstallment.TotalInvoiceAmount = b.TotalInvoiceAmount;
  this.bulkInstallment.TotalPaidAmount = b.TotalPaidAmount;
  this.bulkInstallment.TotalBalance = b.TotalBalance;
  this.bulkInstallment.TotalAmount = b.TotalAmount;
  this.bulkInstallment.Remarks = b.Remarks;
  this.bulkInstallment.BulkInstallments.forEach(element => {
   let bulkDetail = this.bulkInstallment.BulkInstallments.find(x => x.G_FK_Sale_Id === b.G_FK_Sale_Id.Id);
   bulkDetail.G_FK_Sale_Id = new ItemViewModel(b.G_FK_Sale_Id.Id,b.G_FK_Sale_Id.Name);
   bulkDetail.G_FK_Sale_Id.Id = element.G_FK_Sale_Id.Id;
   bulkDetail.G_FK_Sale_Id.Name = element.G_FK_Sale_Id.Name;
   bulkDetail.InvoiceAmount = element.InvoiceAmount;
   bulkDetail.PaidAmount = element.PaidAmount;
   bulkDetail.RemainingAmount = element.RemainingAmount;
   bulkDetail.Amount = element.Amount;
   bulkDetail.TransactionDate = new Date(element.TransactionDate);
  });
    // if (this.calingUrl.startsWith('/purchase')) {
    //   this.vmI.Id = b.Id;
    //   this.vmI.TransactionDate = new Date(b.CreationDate);
    //   this.vmI.Amount = b.Amount;
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
    //   // this.instalment.FK_Account.Id = item.PaidFrom.Id;
    //   // this.instalment.FK_Account.Name = item.PaidFrom.Name;
    //   this.vmI.Remarks = b.Remarks;
    //   this.vmI.Attachment = b.Attachment;
    //   this.isEdit = true;
    //   }

    // if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
    //   this.vmI.Id = b.Id;
    //   this.vmI.TransactionDate = new Date(b.CreationDate);
    //   this.vmI.Amount = b.Amount;
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
    //   // this.instalment.FK_Account.Id = item.PaidFrom.Id;
    //   // this.instalment.FK_Account.Name = item.PaidFrom.Name;
    //   this.vmI.Remarks = b.Remarks;
    //   this.vmI.Attachment = b.Attachment;
    // }

    // if (this.calingUrl.startsWith('/direct-sale-list')) {
    //   this.vmI.Id = b.Id;
    //   this.vmI.TransactionDate = new Date(b.CreationDate);
    //   this.vmI.Amount = b.Amount;
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
    //   // this.instalment.FK_Account.Id = item.PaidFrom.Id;
    //   // this.instalment.FK_Account.Name = item.PaidFrom.Name;
    //   this.vmI.Remarks = b.Remarks;
    //   this.vmI.Attachment = b.Attachment;
    // }

    // if (this.calingUrl.startsWith('/payment-status')) {
    //   this.vmI.Id = b.Id;
    //   this.vmI.TransactionDate = new Date(b.CreationDate);
    //   this.vmI.Amount = b.Amount;
    //   this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
    //   // this.instalment.FK_Account.Id = item.PaidFrom.Id;
    //   // this.instalment.FK_Account.Name = item.PaidFrom.Name;
    //   this.vmI.Remarks = b.Remarks;
    //   this.vmI.Attachment = b.Attachment;
    // }
  }

  conformDelete(e) {

     let delBInst = this.bulkInstallment.BulkInstallments.find(x => x.G_FK_Sale_Id.Id == e.G_FK_Sale_Id.Id);
     this.bulkInstallment.BulkInstallments.splice(this.bulkInstallment.BulkInstallments.indexOf(delBInst),1);
      this.InvoicesTotal();
     // this._uow.saleService.DeleteBulk(this.bulkInstallment).subscribe((res: any) => {
    //   this.ngOnInit();
    //   this.modalRef.hide();
    //   this.InvoicesTotal();
    //   Swal.fire(
    //     'Saved!',
    //     'Repair Order Deletd!',
    //     'success'
    //   )
    // }, (err) => {
    //   Swal.fire(
    //     'Saved!',
    //     'Repair Order Not Deletd!',
    //     'error'
    //   )
    // });
    // if (this.calingUrl.startsWith('/purchase')) {
    //   this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
    //     this.getLatestPurchaseData();
    //     Swal.fire(
    //       'Deleted!',
    //       'Installment Deleted!',
    //       'success'
    //     )
    //     this.modalRef.hide();
    //   }, (err) => {
    //     console.log(err);
    //     Swal.fire(
    //       'Failure!',
    //       'Installment Not Deleted!',
    //       'error'
    //     )
    //    });
    //   }

    // if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
    //   this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
    //     this.getSaleList();
    //     this.getLatestSaleData();
    //     Swal.fire(
    //       'Deleted!',
    //       'Installment Deleted!',
    //       'success'
    //     )
    //     this.modalRef.hide();
    //   }, (err) => {
    //     console.log(err);
    //     Swal.fire(
    //       'Failure!',
    //       'Installment Not Deleted!',
    //       'error'
    //     )
    //    });
    // }

    // if (this.calingUrl.startsWith('/direct-sale-list')) {
    //   this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
    //     this.getSaleList();
    //     this.getLatestSaleData();
    //     Swal.fire(
    //       'Deleted!',
    //       'Installment Deleted!',
    //       'success'
    //     )
    //     this.modalRef.hide();
    //   }, (err) => {
    //     console.log(err);
    //     Swal.fire(
    //       'Failure!',
    //       'Installment Not Deleted!',
    //       'error'
    //     )
    //    });
    // }
    // if (this.calingUrl.startsWith('/payment-status')) {
    //   this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
    //     this.getSaleList();
    //     this.getLatestSaleData();
    //     Swal.fire(
    //       'Deleted!',
    //       'Installment Deleted!',
    //       'success'
    //     )
    //     this.modalRef.hide();
    //   }, (err) => {
    //     console.log(err);
    //     Swal.fire(
    //       'Failure!',
    //       'Installment Not Deleted!',
    //       'error'
    //     )
    //    });
    // }
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);

  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vmI = new G_Installment_View_Model();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.bulkInstallment = model;
    }
  }

}
