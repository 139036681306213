import { Injectable } from '@angular/core';
import { G_StatusTypeViewModel } from 'src/app/viewmodels/g-settings/G_StatusTypeViewModel';
import { HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { IHttpService } from '../../common/IHttpService';

@Injectable({
  providedIn: 'root'
})
export class StatusTypeService implements IHttpService<G_StatusTypeViewModel>  {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_StatusTypeList'));
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_StatusTypeById'), { Id: id });
  }
  Post(obj: G_StatusTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_StatusType'), obj);
  }

  Put(obj: G_StatusTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_StatusType'), obj);
  }

  Delete(obj: G_StatusTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_StatusType'), obj);
  }
}
