export class ItemStockReportViewModel {

          public Id : number;
          public itemCode : string;
          public itemName : string;
          public itemPrice : number;
          public InQuantity : number;
          public AssignQuantity : number;
          public TotalQuantity : number;
          public TotalAmount : number;
}
