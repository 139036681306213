import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GItemDefiningViewModel, WareHouseItemViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { AppConsts } from 'src/app/common/app-consts';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRef: BsModalRef;
  public itemVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public tagtypeVm: G_TagsTypeViewModel = new G_TagsTypeViewModel();
  public wareHouseItemVm:WareHouseItemViewModel= new WareHouseItemViewModel();
  public wareHouseItemList:Array<WareHouseItemViewModel>=[];
  public uploadObj:any={
     file:''
  }
  public vmListItems: any = [];
  public SortBy:string = "asc";
  p: number = 1;
  public component_Title = 'Item';
currentUser:any={};
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    this.currentUser = this._uow.authenticationService.getUser();
   }

  ngOnInit() {
    // this.initializeDataTable();
    this.spinner.show();
    this.itemVm = new GItemDefiningViewModel();
    this.getItemTypes().then(() => {
      
      this.getList();
    });
  }

  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }
  openExcelModal(template: TemplateRef<any>){
   this.modalRef = this._modalService.show(template);
  }
  openModalXL(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
    this.modalRef.setClass('modal-xl');
  }
  ngAfterViewInit() {
    // this._jq.showSpinner();
  }
  onPageChange(e){
    console.log(e);
    this.p = e;
  }
  public itemType = new ItemViewModel();
  loadList(flag) {
    debugger
    this.itemVm = new GItemDefiningViewModel();
    this.itemVm.FK_G_ItemType_Id = new ItemViewModel(flag.Id, flag.Name);
    this.itemType = new ItemViewModel(flag.Id, flag.Name);
    this.getList();
  }
  getSortIndexList(sIndex) {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.itemService.GetPagedResultBySort(0, 50, AppConsts.ITEM, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getList() {
    // this._jq.showSpinner(true);
    // this.spinner.show();
    this._uow.itemService.GetPagedResult(0, 50, this.itemType.Name, 0,this.searchTerm).subscribe((res: any) => {
      this.vmListItems =[];
      this.p = 1;
      this.vmListItems = res.DtlList;
      console.log(res);
      
      // this._jq.initDataTable();
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Something Went wrong',
        'error'
      );
    });
  }
  itemTypes = [];
  getItemTypes() {
return new Promise((resolve,reject) => {
this._uow.itemTypeService.GetList().subscribe((res: any) => {
  this.itemTypes = res;
  this.itemType = this.itemTypes[0];
  resolve(res);
  // this.getList();
}, (err) =>
this._uow.logger.log(err));
});    
}
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    debugger
    this.openModal(template, 1);
    this.modalRefLv1.setClass('modal-xl');
    if (model !== undefined) {
      this.itemVm = model;
      this.itemVm.FK_G_ItemType_Id = this.itemType;
    } else {
      this.itemVm = undefined;
    }
  }

  openSaveOrUpdateModelForItemType(template: TemplateRef<any>, model?, itemType?) {
    debugger
    this.itemType = this.itemTypes[itemType];
    this.openSaveOrUpdateModel(template, model);
  }
  openuploadExcelModal(template: TemplateRef<any>){
    this.openExcelModal(template)
  }
  openWareHouseItemModal(template: TemplateRef<any>, model?){
    this.wareHouseItemVm.FK_Item_Id = new ItemViewModel(model.Id,model.Name);
    this._uow.wareHouseItemService.GetG_WareHouseItemList(model.Id).subscribe((res:any)=>{
      console.log('list ware house item',res);
      this.wareHouseItemList = res;
    });
    this.openModalXL(template);
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      this.spinner.show();
      this.getList();
      // // this._jq.showSpinner(true);
      // this.spinner.show();
      // this._uow.itemService.GetPagedBySearch(this.searchTerm, this.itemType).subscribe((res: any) => {
      //   this.vmListItems = res.DtlList;
      //   // this._jq.showSpinner();
      //   this.spinner.hide();
      // }, (error) => {
      //   console.log(error);
      //   // this._jq.showSpinner();
      //   this.spinner.hide();
      // });
    }
    else {
      this.getList();
    }
  }
  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new GItemDefiningViewModel();
      this.getList();
    }
  }
  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.itemService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }
  public uploadFlag:boolean = false;
  attachfile(event){
    if(event.target.files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
      event.target.files[0].Name= '';
      this.uploadFlag = true;
      return;
    }
    else{
      this.uploadFlag = false;
      this.itemVm.file = event.target.files[0];
    }
    console.log(event.target.files[0]);
    // event.target.files[0]
  }
  onclick(event){
    event.target.value = '';
    this.itemVm.file = undefined;
  }
  uploadExcel(){
    this.spinner.show();
    this._uow.itemService.SaveG_SSItemDefiningByExcel(this.itemVm).toPromise().then((res: any) =>{
  this.spinner.hide();
  Swal.fire( 'Uploaded!',
  'Excel Uploaded!',
  'success');
  this.getItemTypes();
  this.modalRef.hide();
}).catch(error =>{
  this.spinner.hide();
  Swal.fire('Failure!',
  'Not Uploaded!',
  'error');
});
  }
  
}
