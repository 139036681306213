import { Injectable } from '@angular/core';
import { G_CountryPhoneCodeViewModel } from 'src/app/viewmodels/g-settings/G_CountryPhoneCodeViewModel';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../auth/authentication.service';
import { Paginator } from 'src/app/viewmodels/paginator';
import { AppConsts } from 'src/app/common/app-consts';

@Injectable({
  providedIn: 'root'
})
export class PhoneCodeServiceService implements IHttpService<G_CountryPhoneCodeViewModel>{
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('Setting');
  }
  GetById(id: any) {
    throw new Error("Method not implemented.");
  }
  Post(obj: any) {
    throw new Error("Method not implemented.");
  }
  Put(obj: any) {
    throw new Error("Method not implemented.");
  }
  Delete(obj: any) {
    throw new Error("Method not implemented.");
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetPhoneCountryCode'));
  }
  GetByImage() {
    return this._client.get(this._ep.getEndpointMethod('GetHeaderFooter'));
  }

}
