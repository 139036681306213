import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { Router, ActivatedRoute } from '@angular/router';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import Swal from 'sweetalert2';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-direct-sale',
  templateUrl: './edit-direct-sale.component.html',
  styleUrls: ['./edit-direct-sale.component.scss']
})
export class EditDirectSaleComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  modalRef: BsModalRef;
  public vmListItems: any = [];
  @Input() public modal_title: any;
  @Input() public vmP: DefineAccountviewModel;
  @Input() public vmp: GPackagesViewModel;
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmDetail: number = 5;
  private submitted = false;
  public component_Title = 'Direct Sale';
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public ccustomerDetail = 'customerDetail';
  public address : any;
  public files: File[] = [];
  public vehicleDetail: any = [];
  public selectedTagsList: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  bsmRef: any;
  @Output() onSubmitted = new EventEmitter<any>();
  public tags: any = [];
  public selectedTagList: any = [];
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedemail : any = [];
  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public SelectedItemServices: any = [];
  public citems: any = 'items';
  public selectedDate: any;
  public DisableSave: boolean = false;
  public EnableNext: boolean = false;
  public isPreview: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;
  public services: any = [];
  public customerAccount : ItemViewModel;
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public PackageSelectionDropdown: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1
  };
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RegistraionNumber',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public calingUrl: string;
  public urlId: number;
  public selectedServices: any = [];
  public maxDate = new Date();

  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  name = 'CP_Email';
  id = 'Id';
  checked = true;
  isdisabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';

  ngOnInit() {
    debugger
    let selectedSaprePartsList : any = [];
    let selectedServicesList : any = [];
    let selectedTagLists: any = [];
    let selectedVehicle: any = [];
    let selectedCustomer: any = [];
    this.calingUrl = this._router.url;

    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];


    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    debugger
    this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
      this.vm = res;
      this.vm.TransactionDate = new Date(res.TransactionDate);
      console.log(res);
      // this._jq.showSpinner();
      debugger
      let veh = new GVechileViewModel();
      veh.Id = this.vm.Fk_G_Vechile_Id.Id;
      veh.RegistraionNumber = this.vm.Fk_G_Vechile_Id.Name;
      selectedVehicle.push(veh);
      this.selectedvehicleDetail = selectedVehicle;

      if (this.vm.VatValue != undefined ) {
        this.vmDetail = this.vm.VatValue;
      }
      let cust = new DefineAccountviewModel();
      cust.Id = this.vm.Fk_G_Customer_Id.Id;
      cust.Acc_Titel = this.vm.Fk_G_Customer_Id.Name;
      selectedCustomer.push(cust);
      this.selectedcustomerDetail = selectedCustomer;

      this.getCustomers();
      this.vm.SpareParts.forEach(x => {

        let ab =  new GPackageItemViewModel();
          ab.Quantity = x.Quantity;
          ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name);
          ab.DescountPercentage = x.Discount;
          ab.SalePrice = 0;
          ab.SalePrice = x.SalePrice;
          ab.Amount = 0;
          ab.Amount = x.TotalAmount;
          ab.DiscountAmount =  0;
          ab.DiscountAmount = x.DiscountAmount;
          this.vmp.SpareParts.push(ab);
          let partssSelected = new GItemDefiningViewModel();
          partssSelected.Id = x.FK_G_Item_Id.Id;
          partssSelected.Name = x.FK_G_Item_Id.Name;
          selectedSaprePartsList.push(partssSelected);
          //this.InventoryCheck(x.FK_G_Item_Id.Id);
          this.discountChange(ab, 0);
        });
      this.selectedSpareParts = selectedSaprePartsList;

      this.vm.SaleDetails.forEach(x => {
        x.VAT_Value = this.vmDetail;
      });

      this.vm.Services.forEach(x => {

        let ab =  new GPackageItemViewModel();
          ab.Quantity = x.Quantity;
          ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name);
          ab.DescountPercentage = x.Discount;
          ab.SalePrice = 0;
          ab.SalePrice = x.SalePrice;
          ab.Amount = 0;
          ab.Amount = x.TotalAmount;
          ab.DiscountAmount =  0;
          ab.DiscountAmount = x.DiscountAmount;
          this.vmp.Services.push(ab);
          let partssSelected = new GItemDefiningViewModel();
          partssSelected.Id = x.FK_G_Item_Id.Id;
          partssSelected.Name = x.FK_G_Item_Id.Name;
          selectedServicesList.push(partssSelected);
          //this.InventoryCheck(x.FK_G_Item_Id.Id);
          this.discountChange(ab, 0);
        });
      this.SelectedItemServices = selectedServicesList;
      this.vm.SalesTagsList.forEach(x =>{
        let ab = new G_TagsViewModel();
        ab.Id = x.Id;
        ab.FK_Tag_Id = x.FK_Tag_Id;
        ab.Name = x.Name;
        let selectedTag = new G_TagsViewModel();
        selectedTag.Id = x.Id;
        selectedTag.FK_Tag_Id = x.FK_Tag_Id;
        selectedTag.Name = x.Name;
        selectedTagLists.push(selectedTag);
      });
      this.selectedTagsList = selectedTagLists;
    });

    if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
      this.itemVm.FK_Tag_List.forEach(x => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.itemVm == undefined) {
      this.itemVm = new GVechileViewModel();
      this.vehicleDetail = [];
      this.selectedvehicleDetail = [];
    } else {

    }
    if (this.vmP == undefined) {
      this.vmP = new DefineAccountviewModel();
      this.customerDetail = [];
      this.selectedcustomerDetail = [];
    } else {

    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Sale';
    }
    if (this.vmp == undefined) {
      this.vmp = new GPackagesViewModel();
      this.vm = new G_SaleMasterViewModel();
      this.vmp.SpareParts = [];
      this.vmp.Services = [];

      this.vm.Fk_G_StatusType_Id = new ItemViewModel(8,"SaleInvoice");
    } else {
      if(this.vm.SalesTagsList !== undefined && this.vm.SalesTagsList.length > 0){
        this.vm.SalesTagsList.forEach(x => {
          this.selectedTagsList.push(new ItemViewModel(x.Id, x.Name));
        });
      }

      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach(x => {
          this.selectedSpareParts.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach(x => {
          this.selectedServices.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
    }
    // this.getList();
    // this.getVehicleDetail();
    // this.selectedvehicleDetail();
    // this.saveOrUpdate();
    this.getimage();
    this.getRegNo();
    //this.getCustomerName();

    this.getRequisitions();
    this.getTags();
    this.getSpareParts();this.getCustomers();
    this.getServices();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
  }

  onChange(e) {
    debugger
    if(this.selectedvehicleDetail.length!=0){
      let item = this.itemVm.Fk_G_Customer_Id_Email;
      if(e == true){
        let i = new G_SaleMasterViewModel();
        this.vm.FK_Customer_Acc_Email = new ItemViewModel(item.Id , item.Name);
        this.vm.IsEmailRequired = true;
    }else{
      this.vm.FK_Customer_Acc_Email = null;
      this.vm.IsEmailRequired = false;
    }
    }else{
      let item = this.selectedemail;
      if(e == true){
        let i = new G_SaleMasterViewModel();
        this.vm.IsEmailRequired = true;
        this.vm.FK_Customer_Acc_Email = new ItemViewModel(item.Id , item.CP_Email);
    }else{
      this.vm.FK_Customer_Acc_Email = null;
      this.vm.IsEmailRequired = false;
    }
    }

}

	onSelect(e) {
		console.log(e);
		this.files.push(...e.addedFiles);

    this.vm.file = this.files;
	}

	onRemove(e) {
		console.log(e);
		this.files.splice(this.files.indexOf(e), 1);
	}

  getRegNo() {
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        // this.itemVm.RegistraionNumber = res.code;
      }, (error) => {
        this._uow.logger.log(error);
      });
    }

    getCustomerName() {
      this._uow.customerService.GetList().subscribe((res: any) => {

        this.customerDetail = res.DtlList;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        // this.itemVm.RegistraionNumber = res.code;
      }, (error) => {
        this._uow.logger.log(error);
      });
    }

    getSupCashList() {
      this.vmP = new DefineAccountviewModel();
        this.vmP.Acc_Titel = this.vmP.Acc_Titel;
        //this.getRegNo();
      this.selectedcustomerDetail = [];
      this.getCustomers();
    }

    getCustomers() {
      debugger
      let Customer = "Customer";
      if (this.vm.PaymentType === 2) {
        Customer = "Cash";
      }

      console.log(this.selectedcustomerDetail);
      this._uow.customerService.GetListSuplier(Customer).subscribe((res: any) => {
        // console.log(res);
        this.customerDetail = res.DtlList;
      }, (error) => {
        this._uow.logger.log(error);
        this.customerDetail = [];
      });
    }

    public TotalPayable = 0;
    public TotalVat : number;
    public TotalPayableAfterVat = 0;
    calculateTotals() {
      this.TotalPayableAfterVat = 0;
      this.TotalVat = 0;
      this.TotalPayable = 0;
      this.vmp.DiscountAmount = 0;
      this.vmp.PartsAmount = 0;
    if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
      this.vmp.SpareParts.forEach(x => {
        this.vmp.PartsAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DescountAmmount > 0) {
          this.vmp.DiscountAmount += (x.DescountAmmount * x.Quantity);
        }
      });
    this.vmp.ServiceAmount = 0;
    this.vmp.Services.forEach(x => {
      this.vmp.ServiceAmount += x.Amount;
      this.TotalVat += x.VatAmount;
      if (x.DescountAmmount > 0) {
        this.vmp.DiscountAmount += (x.DescountAmmount * x.Quantity);
      }
    });
    }
      this.vmp.PackageAmount = this.vmp.PartsAmount +this.TotalVat + this.vmp.ServiceAmount;
    }

    getRequisitions() {
     // console.log(this.selectedvehicleDetail);
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        // console.log(res);
        this.vehicleDetail = res;
      }, (error) => {
        this._uow.logger.log(error);
        this.vehicleDetail = [];
      });
    }

    onSelectvehicleDetail(e) {
      this._uow.logger.log(e);
      let selectedCustomerList : any = [];
      let item = this.vehicleDetail.find(x => x.Id === e.Id);
      if(item !== undefined){
        this.itemVm = item;
        this.itemVm.Name = item.Name;
        this.itemVm.RegistraionNumber = item.RegistraionNumber;
        this.itemVm.FK_Tag_List  = item.FK_Tag_List;
        this.itemVm.Fk_G_Customer_Id.Id = item.Fk_G_Customer_Id.Id;
        this.itemVm.Fk_G_Customer_Id.Name = item.Fk_G_Customer_Id.Name;
        this.vm.FK_Customer_Acc = new ItemViewModel(item.Fk_G_Customer_Id.Id, item.Fk_G_Customer_Id.Name);
        let custList = new DefineAccountviewModel();
        custList.Id = this.itemVm.Fk_G_Customer_Id.Id;
        custList.Acc_Titel = this.itemVm.Fk_G_Customer_Id.Name;
        selectedCustomerList.push(custList);
        console.log(this.itemVm);
      }

    this.selectedcustomerDetail = selectedCustomerList;

    }

    onDeSelectvehicleDetail(e) {
      if (this.selectedvehicleDetail.length == 0) {
        this.itemVm = new GVechileViewModel();
        this.itemVm.RegistraionNumber = this.itemVm.RegistraionNumber;
        this.getRegNo();
        this.vmP = new DefineAccountviewModel();
        this.vmP.Id = this.vmP.Id;
        this.vmP.Acc_Titel = this.vmP.Acc_Titel;
        this.getCustomerName();
      }
    }

    getVehicleDetail() {
      // 'Spare-Parts'
      this._uow.vehicleService.GetById('Vehicle-Detail').subscribe((res: any) => {
        this.vehicleDetail = res;
        console.log(res);

      }, (err) => {
        this._uow.logger.log(err);
        this.vehicleDetail = [];
      });
    }

    onSelectcustomerDetail(e) {
      let selectedCustomerL : any = [];
      this._uow.logger.log(e);
      if (this.vm.PaymentType ===1) {
      let selectedVehicle : any = [];
      let item = this.customerDetail.find(x => x.Id === e.Id);
      let item2 = this.vehicleDetail.find(y=> y.Id === e.Id);
      if(item !== undefined){
      this.vmP = item;
      this.vmP.Id = item.Id;
      this.vmP.Acc_Titel = item.Acc_Titel;
      this.itemVm = item2;
      this.itemVm.RegistraionNumber = item2.RegistraionNumber;
      let VehList = new GVechileViewModel();
      VehList.Fk_G_Customer_Id.Id = this.vmP.Id;
      VehList.Fk_G_Customer_Id.Name = this.vmP.Acc_Titel;
      this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
      this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
      this.vm.FK_Customer_Acc = new ItemViewModel(this.vmP.Id, this.vmP.Acc_Titel);
      selectedCustomerL.push(VehList);
       VehList.RegistraionNumber = this.itemVm.RegistraionNumber;
       VehList.DriverName = this.itemVm.DriverName;
       VehList.DriverPhone = this.itemVm.DriverPhone;
       VehList.Odometer = this.itemVm.Odometer;
        this.itemVm.DriverName = VehList.DriverName;
        this.itemVm.DriverPhone = VehList.DriverPhone;
        this.itemVm.Odometer = VehList.Odometer;
        selectedVehicle.push(this.itemVm);
      }
      selectedCustomerL = selectedVehicle = this.selectedvehicleDetail;
      selectedCustomerL = this.selectedcustomerDetail;
      selectedVehicle = this.selectedvehicleDetail;
      }else{
        let VehList = new GVechileViewModel();
      VehList.Fk_G_Customer_Id.Id = e.Id;
      VehList.Fk_G_Customer_Id.Name = e.Acc_Titel;
      this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
      this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
      this.itemVm.Fk_G_Customer_Id.Id = e.Id;
      this.itemVm.Fk_G_Customer_Id.Name = e.Acc_Titel;
      this.vm.FK_Customer_Acc = new ItemViewModel(e.Id, e.Acc_Titel);
      selectedCustomerL.push(VehList);
      }
    }

    onDeSelectcustomerDetail(e) {
      if (this.selectedcustomerDetail.length == 0) {
        this.vmP = new DefineAccountviewModel();
        this.vmP.Acc_Titel = this.vmP.Acc_Titel;
        this.getRegNo();
        }
    }

    getCustomerDetail() {
      // 'Spare-Parts'
      this._uow.customerService.GetById('Customer-Detail').subscribe((res: any) => {
        this.customerDetail = res;
        console.log(res);

      }, (err) => {
        this._uow.logger.log(err);
        this.customerDetail = [];
      });
    }

    quantityChange(e, i?) {
      debugger
      //e.DiscountAmount = 0;
      e.Amount = e.Quantity * e.DiscountAmount;
      let saledetail = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id);
      if(saledetail != undefined){
        saledetail.Quantity = e.Quantity;
        saledetail.TotalAmount = e.Amount;
      }
      //  if (e.DescountPercentage > 0) {
      //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
      //  }
      //this.calculateTotals();
      this.vatChanged();
      this.InventoryCheckAll();
    }

    discountChange(e, i?) {
      let discountAMmount = ((e.DescountPercentage * e.SalePrice)) / 100 ;
      e.DescountAmmount =discountAMmount
      e.DiscountAmount = e.SalePrice-e.DescountAmmount;
      e.Amount = e.DiscountAmount * e.Quantity;
      let saledtl = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id);
     if (saledtl != undefined) {
      saledtl.Rate = e.SalePrice;
      saledtl.DiscountAmount = e.DescountAmmount;
      saledtl.Discount = e.DescountPercentage;
      saledtl.DiscountedRate = e.DiscountAmount;
      saledtl.TotalAmount =e.Amount
     }
      // if(this.vmP.DiscountAmount > 0){
      //
      //   this.calculateTotals();
      // }
      // if(this.vmP.DiscountAmount <= 0){
      //   let discountAMmount = ((e.DescountPercentage / 100) * e.Amount);
      //   e.Amount = (e.Amount - discountAMmount);
      //   this.calculateTotals();
      // }
      this.vatChanged();
      //this.calculateTotals();
    }

    amountChange(e, i?) {
      e.DescountPercentage = ((e.DescountAmmount * 100) / e.SalePrice);
      this.discountChange(e, i);
      this.vatChanged();
      //this.calculateTotals();
      // if(this.vmP.DiscountAmount > 0){
      //
      //   this.calculateTotals();
      // }
      // if(this.vmP.DiscountAmount < 0){
      //   let discountAMmount = (e.Amount-e.DescountAmmount);
      //   e.Amount = (discountAMmount);

      // }
    }

    vatChanged() {
      if (this.vmDetail > -1) {
        if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
          this.vm.SaleDetails.forEach(x => {
            x.VAT_Value = this.vmDetail;
            x.VatAmount = ((x.TotalAmount / 100) * this.vmDetail);
          });
        }
        if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
          this.vmp.SpareParts.forEach(x => {
            x.VatAmount = ((x.Amount / 100) * this.vmDetail);
            x.TotalAmount = x.Amount + x.VatAmount;
          });
        }

      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach(x => {
          x.VatAmount = ((x.Amount / 100) * this.vmDetail);
          x.TotalAmount = x.Amount + x.VatAmount;
        });
      }
      this.calculateTotals();
      }
    }

    vatAmountChanged(i) {
      this.calculateTotals();
    }


  getSortIndexList(sIndex) {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.vehicleService.GetPagedResult(0, 50, sIndex).subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GVechileViewModel();
    }
  }

  onSelectTags(e) {
    if (this.vm.SalesTagsList == undefined || this.vm.SalesTagsList.length == 0) {
      this.vm.SalesTagsList = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      i.FK_Tag_Id =  item.Id;
      this.vm.SalesTagsList.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);

      }

  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTagsList.find(x => x.FK_Tag_Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTagsList.splice(this.vm.SalesTagsList.indexOf(i), 1);
    }

  }
  //'Spare-Parts'
  getSpareParts() {
    this._uow.itemService.GetSSItemDefiningByItemType('Spare-Parts').subscribe((res: any) => {
      this.spareParts = res;
      console.log(res);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
  }

  onSelectSparePart(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);
    let item = this.spareParts.find(x => x.Id === e.Id);
  if (item !== undefined) {
    let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a =  new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount =  item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.SpareParts.push(a);
      this.discountChange(this.vmp.SpareParts[this.vmp.SpareParts.indexOf(a)]);
      //this.selectedSpareParts.push(a);
    }
    this.vatChanged();
    this.calculateTotals();
    this.InventoryCheck(e.Id);
  }

  onDeSelectSparePart(e) {
    debugger
    let i = this.vmp.SpareParts.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmp.SpareParts.splice(this.vmp.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    // let a = this.vm.SpareParts.find(x=> x.FK_G_Item_Id.Id === e.Id);
    //   if(a !== undefined){
    //     this.vm.SpareParts.splice(this.vm.SpareParts.indexOf(a),1);
    //   }

    this.calculateTotals();
    this.InventoryCheckAll();
  }

  //service
  onSelectService(e) {
    debugger
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    if (this.SelectedItemServices == undefined || this.SelectedItemServices.length == 0) {
      this.SelectedItemServices = [];
    }
    this._uow.logger.log(e);
    let item = this.services.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a =  new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount =  item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.Services.push(a);
      this.discountChange(this.vmp.Services[this.vmp.Services.indexOf(a)]);
      this.SelectedItemServices.push(item);
    }
    this.vatChanged();
    this.calculateTotals();
  }

  onDeSelectService(e) {

    let i = this.vmp.Services.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmp.Services.splice(this.vmp.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  getServices() {
    // 'Services'
    this._uow.itemService.GetSSItemDefiningByItemType('Services').subscribe((res: any) => {
      this.services = res;
      console.log(res);
      // this._uow.logger.log(`services ${res}`);
    }, (err) => {
      this._uow.logger.log(err);
      this.services = [];
    });
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }

  saveOrUpdate() {
    debugger
    this.vm.IsEmailRequired == true;
    if(this.vm.PaymentType==1){
      this.onChange(true);
    }
    this.spinner.show();
    // this._jq.showSpinner(true);
    this._uow.vehicleService
      .Post(this.itemVm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
      })
      .catch((error) => {
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
      });

      this.vm.Inv_Amount = this.vmp.PackageAmount;
          //this.vm.PaidAmount = this.vm.Inv_Amount;
          this.vm.VatValue  = this.vmDetail;
          this.vm.IsPaymentDone  = false;
          this.vm.IsCredit = false;
          this.vm.IsCounterSale =true;
          //this.vm.Odometer = this.itemVm.Odometer;


      this._jq.showSpinner(true);
      let obj = this.itemVm;
      console.log();
      this._jq.showSpinner(true);
      this._uow.saleService
        .Post(this.vm)
        .toPromise().then((res: any) => {
          console.log(res);
          this.vm = res;
          this.submitted = true;
          this.onSubmitted.emit(this.submitted);
          //this.bsmRef.hide();
          this.vm = res;
          this._jq.showSpinner();
          this.EnableNext = true;
          this.modalRef.hide();
          this.spinner.hide();
          Swal.fire(
            'Saved!',
            'Direct Sale Updated!',
            'success'
          )
          this._router.navigate(['/direct-sale-list']);

        })
        .catch((error) => {
          Swal.fire(
            'Error!',
            'Direct Sale Not Updated!',
            'error'
          )
          this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
          this.spinner.hide();
        });
  }

  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.vehicleService.Post(this.vm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRefLv1.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  OpenCreateDirectInvoice(e){
    this._router.navigate(['/direct-sale-invoice', {id: e}]);
  };


  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  InventoryCheck(e){
    let SparepartsEvent = this.spareParts.find(y => y.Id === e);
    if (SparepartsEvent != undefined && SparepartsEvent != null) {
      let AlreadySelectedParts = this.vm.SpareParts.find(y => y.FK_G_Item_Id.Id === e);
      if (AlreadySelectedParts != undefined && AlreadySelectedParts != null) {
        let AlreadyPartsIndex = this.vm.SpareParts.indexOf(AlreadySelectedParts);
        if (AlreadySelectedParts.Quantity > SparepartsEvent.CurrentStock) {
          if (AlreadyPartsIndex != undefined) {
            this.vm.SpareParts[AlreadyPartsIndex].IsInventry = false;
          }
          this.DisableSave = true;
        }else{
          if (AlreadyPartsIndex != undefined) {
            this.vm.SpareParts[AlreadyPartsIndex].IsInventry = true;
          }
          AlreadySelectedParts.IsInventry = true;
          this.DisableSave = false;
        }
      }
    }
  }

  InventoryCheckAll(){
    debugger
    if (this.vm.SpareParts != undefined && this.vm.SpareParts.length > 0) {
      this.vm.SpareParts.forEach(x=> {
        let SparepartsEvent = this.spareParts.find(y => y.Id === x.FK_G_Item_Id.Id);
        if (SparepartsEvent != undefined && SparepartsEvent != null) {
            if (x.Quantity > SparepartsEvent.CurrentStock) {
                x.IsInventry = false;
                this.DisableSave = true;
            }
            else{
                x.IsInventry = true;
                this.DisableSave = false;
            }
        }
      });
    }else{
      this.DisableSave = false;
    }

  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find(x => x.Name == 'Spare-Parts');
      console.log(i)
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find(x => x.Name == 'Services');
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }


  conformDelete() {
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.ngOnInit();
      this.modalRefLv1.hide();
      Swal.fire(
        'Saved!',
        'Direct Sale Deleted!',
        'success'
      )

    }, (err) => {
      Swal.fire(
        'Saved!',
        'Direct Sale Not Deleted!',
        'success'
      )
    });
  }

  // external components
  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      console.log(res);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  navigateClick(){
    // this.isPrevi ew = false;
    this.modalRef.hide()
  }

  openPreview(content) {
    debugger
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass('modal-lg');
    this.isPreview = true;
  }
}
