import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { RouteConsts } from 'src/app/common/route-consts';

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit {

  public _routeConsts = new RouteConsts();
  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
  }

}
