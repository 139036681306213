import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/services/common/cookie.service';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as $ from './../../../assets/js/jquery.min.js';
import { JqHelper } from 'src/app/common/jq-helper.js';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public subscription: Subscription;
  public returnUrl: string = '';

  public user: IdentityUserLoginModel = new IdentityUserLoginModel();
  public showError: boolean = false;
  public showUpdateError: boolean = false;
  public errorString: string = '';
  public loadingClass: string = 'register-hr';
  public loginladda = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _jq:JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService) {
    // let element = document.getElementsByTagName("body")[0];
    // element.classList.remove("sidebar-lg-show");
  }

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe(
      (params: any) => {
        if (params.hasOwnProperty('returnUrl')) {
          this.returnUrl = params['returnUrl'];
        }
      }
    );
    if (this._uow.cookieService.getCookie('ussr')) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngAfterViewInit() {
    this._jq.showSpinner();
  }


  public login() {
    this._jq.loginLoading(true);
    this._uow.authenticationService.login({ username: this.user.username, password: this.user.password }).subscribe(
      (response: any) => {
        if (this._uow.authenticationService.setUser(response)) {
          // this.loading(false);
          this.router.navigate(['/' + this.returnUrl]);
        }
      },
      err => {
        if (err.error && err.error.error_description && err.error.error_description == 'NA') {
          this.showUpdateError = true;
        } else {
          this.showUpdateError = false;
          this.showError = true;
        }
        this._jq.loginLoading();
      }
    );
  }

  //Loading Helper Function
  // public loading(start: boolean) {
  //   if (start) {
  //     this.loadingClass = 'register-hr-anime';
  //   } else {
  //     this.loadingClass = 'register-hr';
  //   }
  //   this.loginladda = true;
  // }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

export class IdentityUserLoginModel {
  public username: string;
  public password: string;
}
