import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { G_BrandsViewModel } from 'src/app/viewmodels/g-settings/G_BrandsViewModel';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { AuthenticationService } from '../../auth/authentication.service';
import { PhoneCodeServiceService } from './phone-code-service.service';
import { G_CountryPhoneCodeViewModel } from 'src/app/viewmodels/g-settings/G_CountryPhoneCodeViewModel';

@Injectable({
  providedIn: 'root'
})
export class SettingService{
  headers: HttpHeaders = new HttpHeaders();
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('Setting');
  }
  GetList() {
    throw new Error("Method not implemented.");
  }
  GetById(id: any) {
    throw new Error("Method not implemented.");
  }
  Post(obj: G_CountryPhoneCodeViewModel) {
    throw new Error("Method not implemented.");
  }
  Put(obj: G_CountryPhoneCodeViewModel) {
    throw new Error("Method not implemented.");
  }
  Delete(obj: G_CountryPhoneCodeViewModel) {
    throw new Error("Method not implemented.");
  }

  GetByImage() {
    return this._client.get(this._ep.getEndpointMethod('GetHeaderFooter'));
  }

}
