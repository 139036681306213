import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: G_TagsViewModel = new G_TagsViewModel();
  public tagtypeVm: G_TagsTypeViewModel = new G_TagsTypeViewModel();
  public vmListItems: any = [];
  public tagTypes: any = [];
  public component_Title = "Tag";

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getList();
    this.getTagTypes();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  }
  getTagTypes() {
    this._jq.showSpinner(true);
    this._uow.tagsTypeService.GetList().subscribe((res: any) =>
    {
      this.tagTypes = res;
    this._jq.showSpinner();
   },
    (err) => console.log(err));
  }

  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.tagsService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
    });
  }



  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
      console.log(this.itemVm)
      this.itemVm.Tags_Type = new ItemViewModel(model.Tags_Type.Id,model.Tags_Type.Name);
    } else {
      this.itemVm = new G_TagsViewModel();
    }
  }
   // extrnal components
   onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new G_TagsViewModel();
      this.getList();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.tagsService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

}
