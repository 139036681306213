import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from '../../common/default.service';
import { Paginator } from 'src/app/viewmodels/paginator';
import { E_G_EPSStatusType } from 'src/app/viewmodels/g-purchase/E_G_EPSStatusType';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService implements IHttpService<G_PurchaseMasterViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _defaultService: DefaultService) {
    this._ep = new EndpointConfig('GPurchase');
  }
  ValidatePurchsaeRefId() {
    return this._client.post(this._ep.getEndpointMethod('ValidatePurchsaeRefId'), { Id: null });
  }

  GetList() {
    let pg = new Paginator(0, 50, null, 0);
    pg.AccId = E_G_EPSStatusType.Purchase_Slip;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pg);
  }

  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    let pg = new Paginator(start, pagesize, flag, sortIndex, SortBy);
    pg.Search = null;
    pg.SortBy = SortBy;
    pg.AccId = E_G_EPSStatusType.Purchase_Slip;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pg);
  }

  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.Purchase_Slip;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pager);
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseById'), { Id: id });
  }

  Post(obj: G_PurchaseMasterViewModel) {
    this._defaultService.dateTransFuckDatesInObject(obj);
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Slip);
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Purchase'), fd);
  }

  Put(obj: G_PurchaseMasterViewModel) {
    this._defaultService.dateTransFuckDatesInObject(obj);
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Slip);
    return this._client.post(this._ep.getEndpointMethod('SaveG_Purchase'), obj);
  }

  Delete(obj: G_PurchaseMasterViewModel) {
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Slip);
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Purchase'), obj);
  }

  //#Installmentregion

  GetInstallmentList(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_Purchase_IntallmentList'), { Id: id });
  }

  GetInstallmentSaleList(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_Sale_IntallmentList'), { Id: id });
  }




  GetInstallmentById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SP_IntallmentById'), { Id: id });
  }

  PostInstallment(obj: G_Installment_View_Model) {
    let fd = new FormData();
    fd.append('file', obj.file);
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_SP_Intallment'), fd);
  }

  PutInstallment(obj: G_Installment_View_Model) {
    this._defaultService.dateTransFuckDatesInObject(obj);
    return this._client.post(this._ep.getEndpointMethod('SaveG_SP_Intallment'), obj);
  }

  DeleteInstallment(obj: G_Installment_View_Model) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_SP_Intallment'), obj);
  }

  PostBulkPayment(obj: G_Bulk_Installment_View_Model) {
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveBulkG_SP_Intallment'), fd);
  }
}
