import { Component, OnInit, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-repair-order-list',
  templateUrl: './repair-order-list.component.html',
  styleUrls: ['./repair-order-list.component.scss']
})
export class RepairOrderListComponent implements OnInit {
  myDate = new Date(Date.now());
  public component_Title = 'Repair Order List';
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public attachmentList: any = [];
  public VmSaleList: any = [];
  modalRef: BsModalRef;
  p: number = 1;
constructor(
  private route: ActivatedRoute,
    private datePipe: DatePipe,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) {
    // this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
}

  ngOnInit() {
    this.getList();
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      this._jq.showSpinner(true);
      this._uow.saleService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vm = res.DtlList;
        this.vmListItems = res.DtlList;
        this._jq.showSpinner();
      }, (error) => {
        console.log(error);
        this._jq.showSpinner();
      });
    } else {
      this.getList();
    }
  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vm = new G_SaleMasterViewModel();
      this.getList();
    } else {
      this._jq.showSpinner();
    }
  }

  getList() {
    this.spinner.show();
    this._uow.saleService.GetPagedResult().subscribe((res: any) => {
      this.vm= res.DtlList;
      this.vmListItems = res.DtlList;
      console.log(res);
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    }
  }

  confirmDelete() {
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.ngOnInit();
      this.modalRef.hide();
      Swal.fire(
        'Saved!',
        'Repair Order Deletd!',
        'success'
      )
    }, (err) => {
      Swal.fire(
        'Saved!',
        'Repair Order Not Deletd!',
        'error'
      );
      this._jq.showSpinner();
    });
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.saleService.GetById(b).subscribe((res: any)=>{
      this.attachmentList = res.AttachmentList;
      console.log(this.attachmentList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  // conformDelete() {
  //   this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRef.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    } else {
      this.vm = new G_SaleMasterViewModel();
    }
  }

}
