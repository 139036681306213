import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { AppConsts } from 'src/app/common/app-consts';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  public itemVm: DefineAccountviewModel = new DefineAccountviewModel();
  public vmListItems: any = [];
  public SortBy:string = "asc";
  public component_Title = 'Manage Customer';
  p: number = 1;
  public tagTypes: any = [];
  @Input('dataTable') table;
  dataTable: any;
  dtOption: any = {};
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    this.getList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
      this.modalRefLv1.setClass('modal-lg');
    }

    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
    if (lvl == 3) {
      this.modalRefLv3 = this._modalService.show(template);
    }
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    debugger
    this._uow.customerService.GetList().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }
  getSortIndexList(sIndex) {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.customerService.GetPagedResult(0, 50, AppConsts.CUSTOMER, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);

      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.customerService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p =1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
      this.spinner.hide();

      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
      this.spinner.hide();

      });
    } else {
      this.getList();
    }
  }
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    if (model !== undefined) {
      this.itemVm = model;
      debugger
      let test = this.itemVm.CP_Cell.split('-');
      if(test.length == 2){
        this.itemVm.CP_Cell = test[1].toString();
        this.itemVm.Country_Code = test[0].toString();
      }
      this.openModal(template, 1);
    } else {
      this.itemVm = undefined;
      this.openModal(template, 1);
    }
  }


  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }
  conformDelete() {
    this.spinner.show();
    this._uow.customerService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
      this.spinner.hide();
    }, (err) => {
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  // external components
  onItemTypeSubmitted(e) {
    if (e == true) {
      this.getList();
      this._jq.showSpinner(false);
    }
  }

}
