import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { InventryFlowReport } from 'src/app/viewmodels/g-inventory/inventory-flow';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-inventory-flow-report',
  templateUrl: './inventory-flow-report.component.html',
  styleUrls: ['./inventory-flow-report.component.scss']
})
export class InventoryFlowReportComponent implements OnInit {
public InventryFlowReport =new InventryFlowReport();
public vmListItems: Array<any> = [];
public vmListWarehouses: any = [];
FK_WareHouse_Id = new ItemViewModel();
selectedSpareParts : Array<any> =[];
inventoryFlowList:Array<InventryFlowReport> =[];
public itemsDropdownSettings: IDropdownSettings = {
  singleSelection: true,
  idField: 'Id',
  textField: 'Name',
  enableCheckAll: false,
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3,
  allowSearchFilter: true,
  clearSearchFilter:true,
  closeDropDownOnSelection: true
};
  constructor(private _uow: UnitOfWorkService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.getSpareParts();
    this.getWarehouse();
  }
  getWarehouse(){
    this._uow.warehouseService.GetListByUserId().subscribe((res:any) =>{
      console.log('res',res);
      this.vmListWarehouses = res;
    });
  }
  getSpareParts() {
  if(this.FK_WareHouse_Id !== undefined){
    this._uow.wareHouseItemService.GetListItemByWare(this.FK_WareHouse_Id.Id).subscribe(
      (res: any) => {
        this.vmListItems = res;
        if(this.vmListItems.length > 0){
         this.vmListItems.forEach(x =>{
           x.Name = x.Code +'-'+ x.Name;
         });
        }
        console.log(res);
      },
      (err) => {
        this._uow.logger.log(err);
        this.vmListItems = [];
      }
    );
  }
  }
  onItemDeSelect(event){
    debugger;
    this.selectedSpareParts =[];
  }
  fetchInventoryFlowReport(){
    console.log('ware',this.FK_WareHouse_Id);
    console.log('item',this.selectedSpareParts);
    debugger;
    const raw ={
      itemId:this.selectedSpareParts[0].Id,
      wareHouseId:this.FK_WareHouse_Id.Id
    };
    this.spinner.show();
    this._uow.itemService.GetG_InventoryFlowReport(raw).subscribe((res:any) =>{
      console.log('report response',res);
      this.inventoryFlowList = res;
      this.spinner.hide();
    },error =>{
      console.log(error);
      this.spinner.hide();
    });
  }
}
