import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { Router } from '@angular/router';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-sale-comp',
  templateUrl: './sale-comp.component.html',
  styleUrls: ['./sale-comp.component.scss']
})
export class SaleCompComponent implements OnInit {

  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  modalRef: BsModalRef;
  public vmListItems: any = [];
  @Input() public modal_title: any;
  @Input() public vmP: DefineAccountviewModel;
  @Input() public vm: G_SaleMasterViewModel;
  private submitted = false;
  public component_Title = 'Sale';
  public vehicleDetail: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  bsmRef: any;
  @Output() onSubmitted = new EventEmitter<any>();
  public tags: any = [];
  public selectedTagList: any = [];

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RegistraionNumber',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  ngOnInit() {
    if (this.vmP == undefined) {
      this.vmP = new DefineAccountviewModel();
      this._uow.accountsService.GetCode().subscribe((res: any) => {
        this.vmP.Acc_Code = res.code;
      }, (err) => {
        console.log(err);
      });
    }
    if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
      this.itemVm.FK_Tag_List.forEach(x => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.itemVm == undefined) {
      this.itemVm = new GVechileViewModel();
      this.vehicleDetail = [];
      this.selectedvehicleDetail = [];
    } else {

    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Sale';
    }
    // this.getList();
    this.getRegNo();
    this.getRequisitions();
    this.getTags();
    // this.getVehicleDetail();
    // this.selectedvehicleDetail();
    // this.saveOrUpdate();
    }

    getRegNo() {
      this.spinner.show();
      this._uow.vehicleService.GetList().subscribe((res: any) => {

        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this.spinner.hide();
        // this.itemVm.RegistraionNumber = res.code;
      }, (error) => {
        this._uow.logger.log(error);
        this.spinner.hide();
      });
    }

    getRequisitions() {
      console.log(this.selectedvehicleDetail);
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        console.log(res);
        this.vehicleDetail = res;
      }, (error) => {
        this._uow.logger.log(error);
        this.vehicleDetail = [];
      });
    }

    onSelectvehicleDetail(e) {
      this._uow.logger.log(e);
      let item = this.vehicleDetail.find(x => x.Id === e.Id);
      this._uow.vehicleService.GetById(e.Id).subscribe((res: any) => {
      console.log(res);
      console.log(this.itemVm);
      this.itemVm = res;
      this.itemVm.Name = res.Name;
      this.itemVm.RegistraionNumber = res.RegistraionNumber;
      this.itemVm.FK_Tag_List  = res.FK_Tag_List;
      console.log(this.itemVm);
    }, (err) => {
      console.log(err);
    });

    }

    onDeSelectvehicleDetail(e) {
      if (this.selectedvehicleDetail.length == 0) {
        this.itemVm = new GVechileViewModel();
        this.itemVm.RegistraionNumber = this.itemVm.RegistraionNumber;
        this.getRegNo();
        }
    }

    getVehicleDetail() {
      // 'Spare-Parts'
      this.spinner.show();
      this._uow.vehicleService.GetById('Vehicle-Detail').subscribe((res: any) => {
        this.vehicleDetail = res;
        console.log(res);
        this.spinner.hide();
      }, (err) => {
        this._uow.logger.log(err);
        this.vehicleDetail = [];
        this.spinner.hide();
      });
    }


  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
      this.modalRefLv1.setClass('modal-lg');
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
    if (lvl == 3) {
      this.modalRefLv3 = this._modalService.show(template);
    }
  }



  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GVechileViewModel();
    }
  }

  onSelectTags(e) {
    if (this.itemVm.FK_Tag_List == undefined || this.itemVm.FK_Tag_List.length == 0) {
      this.itemVm.FK_Tag_List = [];
    }
    this._uow.logger.log(e);
    const item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {
      const a = new G_TagsViewModel();
      a.Id = item.Id;
      a.Name = item.Name;
      this.itemVm.FK_Tag_List.push(a);

      }

  }

  onDeSelectTags(e) {
    const i = this.itemVm.FK_Tag_List.find(x => x.Id === e.Id);
    if (i !== undefined) {
      this.itemVm.FK_Tag_List.splice(this.itemVm.FK_Tag_List.indexOf(i), 1);
    }

  }

  getTags() {
    this.spinner.show();
    this._uow.tagsService.GetList().subscribe((res: any) =>
    {
       this.tags = res;
       this.spinner.hide();

      },
      (err) =>
      {
        console.log(err);
        this.spinner.hide();

      });
  }

  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.vehicleService
      .Post(this.itemVm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
        // this._jq.showSpinner();
        this.spinner.hide();

        Swal.fire(
          'Updated!',
          'Vehicle Updated!',
          'success'
        )
        // this.bsmRef.hide();
        this._router.navigate(['/repair-order', {id: this.itemVm.Id}]);
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Vehicle Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
        // this._jq.showSpinner();
        this.spinner.hide();

      });
  }

  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.vehicleService.Post(this.vm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRefLv1.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.vehicleService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  // external components
  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
      this.getTags();
      this.spinner.hide();
    }
  }

  getList() {
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      console.log(res);
      this.spinner.hide();

    }, (err) => {
      console.log(err);
      this.spinner.hide();

    });
  }

}
