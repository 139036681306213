import { ItemViewModel } from '../ItemViewModel';
import { GPackagesModelViewModel } from './GPackagesModelViewModel';
import { GPackageItemViewModel } from './GPackageItemViewModel';
import { GPackageSSItemViewModel } from './GPackageSSItemViewModel';

export class GPackagesViewModel {
    public Id: number;
    public Name: string;
    public Description: string;
    public Fk_PackageType_Id: ItemViewModel;
    // public FK_ClientId: ItemViewModel;
    public PartsAmount: number;
    public ServiceAmount: number;
    public TotalAmount: number;
    public DiscountAmount: number;
    public PackageAmount: number;
    public Discount: number;
    public IsGenericModel: boolean;
    // public IsActive: boolean;
    public CreatedDate?: Date;
    public GPackagesModel: GPackagesModelViewModel[];
    public GPackagesItem: GPackageItemViewModel[];
    // public User: string;
    public Services: GPackageItemViewModel[];
    public SpareParts: GPackageItemViewModel[];
}

