import { ItemViewModel } from '../ItemViewModel';

export class GVechileViewModel {
  constructor(){
    this.FK_G_Model = new ItemViewModel();
    this.Fk_G_Brand = new ItemViewModel();
    this.Fk_G_Customer_Id = new ItemViewModel();
    this.FK_TagId = new ItemViewModel();
    this.Fk_G_Customer_Id_Email = new ItemViewModel();
  }
    public Id: number;
    public Name: string;
    public Description: string;
    public RegistraionNumber: string;
    public ChasisNumber: string;
    public EngineNumber: string;
    public Bike_Engg_Power: string;
    // public Bike_Insured_By: string;
    // public Bike_Garage: string;
    // public Bike_Salik:boolean;
    public Colour: string;
    public Odometer: number;
    public PurchaseDate: Date;
    // public ModelYear: string;
    public Fk_G_Brand: ItemViewModel;
    public FK_G_Model: ItemViewModel;
    public Fk_G_Customer_Id: ItemViewModel;
    public Fk_G_Customer_Id_Email: ItemViewModel;
    public DriverName: string;
    public DriverPhone: string;
    public Fk_G_Customer_Discount: number;
  public SaleDetails: any;
  public PurchaseDetail: any;
  public FK_TagId: ItemViewModel;
  public FK_Tag_List: ItemViewModel[];
}

