import { ItemViewModel } from '../ItemViewModel';

export class StockAdjustmentDetailViewModel {

  public Id : number;
  public FK_G_StockAdjustment_Id : ItemViewModel;
  public Fk_G_WareHouse_Id : ItemViewModel;
  public FK_G_Item_Id : ItemViewModel;
  public Quantity : number;
  public OldQuantity : number;
  public NewQuantity : number;
  public CostofGoodSold : number;
  public TotalAmount : number;
  public NewAmount : number;
  public TransactionDate : Date;
  public IsDisable:boolean = false;
  public IsLock? : boolean;
  public Name:string;
}
