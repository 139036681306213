import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GPurchaseRequisitionViewModel } from 'src/app/viewmodels/g-purchase/GPurchaseRequisitionViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public itemVm: G_PurchaseMasterViewModel;
  public invoicesList: any = [];
  public vmListItems: any = [];
  public component_Title = 'Purchase Order';
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }
  openModalxl(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.purchaseService.GetById(b).subscribe((res: any)=>{
      this.invoicesList = res.InvoicesList;
      console.log(this.invoicesList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }
  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.purchaseOrderService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.purchaseOrderService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    } else {
      this.getList();
    }
  }

  getSortIndexList(sIndex) {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.purchaseOrderService.GetPagedResult(0, 50, null, sIndex, this.SortBy).subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      // this._jq.showSpinner();
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModalxl(template);
    if (model !== undefined) {
      this.itemVm = model;
      this.itemVm.TransactionDate = this._uow.defaultService.CutTimeZone(this.itemVm.TransactionDate);
    } else {
      this.itemVm = undefined;
    }
  }

  // extrnal components
  onItemSubmitted(e) {
    document.location.reload(true);
    console.log(e);
    if (e === true) {
      this.itemVm = new G_PurchaseMasterViewModel();
      this.getList();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.purchaseOrderService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
      this.spinner.hide();
    }, (err) => {
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

}
