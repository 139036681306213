import { Component, OnInit, TemplateRef } from '@angular/core';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  modalRef: BsModalRef;
  public itemVm: GPackagesViewModel = new GPackagesViewModel();
  public vmListItems: any = [];
  public component_Title = 'Package';
  p: number = 1;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(){
    this.getList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.packageService.GetList().subscribe((res: any) => {
      this.vmListItems = res;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GPackagesViewModel();
      this.itemVm.SpareParts = [];
      this.itemVm.Services = [];
    }
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.packageService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.packageService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      },  (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new GPackagesViewModel();
      this.getList();
    } else {
      this._jq.showSpinner();
    }
  }
}
