import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  constructor(private datePipe: DatePipe) {
  }
  public getDateString(d: Date) {
    return `${d.getMonth()}/${d.getDay()}/${d.getFullYear()}`;
  }

  public getDateWithoutTime(d: Date) {
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0, 0).toUTCString();
  }

  public dateTransFuckDate(d: Date) {
    return this.datePipe.transform(d, 'MM/dd/yyyy');
  }

  public CutTimeZone(date: any) {
    let d = new Date(date);
    d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
    return d;
  }
  public dateTransFuckDatesInObject(obj) {
    // get all keys of an object
    let keys = Reflect.ownKeys(obj);
    // get keys that have 'date' in em
    let dateKeys = keys.filter(x => x.toString().toLowerCase().includes('date'));
    // transform the fuck out of it fuck it all up with reflection
    dateKeys.forEach(key => {
      Reflect.set(obj, key, this.dateTransFuckDate(Reflect.get(obj, key)));
    });

  }
}
