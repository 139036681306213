import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { IHttpService } from '../../common/IHttpService';
import { GAggingReportViewmodel } from 'src/app/viewmodels/g-settings/GAggingReportViewmodel';
import { HttpClient } from '@angular/common/http';
import { Paginator } from 'src/app/viewmodels/paginator';
import { GSalesReportByDate } from 'src/app/viewmodels/g-settings/GSalesReportByDate';

@Injectable({
  providedIn: 'root'
})
export class ReportingServiceService implements IHttpService<GAggingReportViewmodel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GReporting');
  }
  GetList() {
    return this._client.post(this._ep.getEndpointMethod('GetG_AggingReport'), new Paginator(0, 50, null, 0));
  }

  GetById(id: any) {
    throw new Error("Method not implemented.");
  }
  Post(obj: GAggingReportViewmodel) {
    throw new Error("Method not implemented.");
  }
  Put(obj: GAggingReportViewmodel) {
    throw new Error("Method not implemented.");
  }
  Delete(obj: GAggingReportViewmodel) {
    throw new Error("Method not implemented.");
  }
  public getCountsForDashboard () {
    return this._client.post(this._ep.getEndpointMethod('GetCountsForDashboard'),{ Id: 0});
  }

}
