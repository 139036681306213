import { ItemViewModel } from '../ItemViewModel';

export class SaleDetailViewModel {
    public Id: number;
    public FK_G_SaleMast_Id: ItemViewModel;
    public Item_Code: string;
    public Item_Name: string;
    public Item_Type: string;
    public Item_Detail: string;
    public FK_G_Item_Id: ItemViewModel;
    public FK_G_Item_Type_Id: ItemViewModel;
    public Quantity: number;
    public AvalibelQuantity: number;
    public AvalibilityStatus: boolean;



    public UOM: string;
    public Remarks: string;
    public PurchasePrice: number;
    public SalePrice: number;
    public CostofGoodSold: number;
    public VAT_Type: string;
    public VAT_Value: number;
    public VatAmount: number;
    public TotalAmount: number;
    public TotalVatAmount: number;
    public Discount: number;
    public DiscountAmount: number;
    public DiscountedRate: number;
    public Rate: number;
    public Batch: number;
    public FK_ClientId: ItemViewModel;
    public IsCompleted: boolean;
    public IsOldItem: boolean;
    public IsInventry:boolean = true;
}
