import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-bulk-payments-list',
  templateUrl: './bulk-payments-list.component.html',
  styleUrls: ['./bulk-payments-list.component.scss']
})
export class BulkPaymentsListComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  public modalRef: BsModalRef;
  public bulkInstallment: G_Bulk_Installment_View_Model = new G_Bulk_Installment_View_Model();
  public vmListItems: any = [];
  public invoicesList: any = [];
  public bulkbyid: any = [];
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Bulk Payment List';
  public SortBy:string = "asc";
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getBulkList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,

  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })

  setTimeout(() => {
    /** spinner ends after 5 seconds */
    this.spinner.hide();
  }, 3000);
  }

  getBulkList(){
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService.GetBulkPaymentList().subscribe((res: any) => {
      this.vmListItems = res;
      this.bulkInstallment.BulkInstallments = res;
      console.log(res);
      // this._jq.showSpinner();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();

    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.bulkInstallment = model;
      this.bulkInstallment.TransactionDate = this._uow.defaultService.CutTimeZone(this.bulkInstallment.TransactionDate);
    } else {
      this.bulkInstallment == undefined;
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
      this.modalRef.setClass('modal-xl');
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.bulkInstallment = model;
    }
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.saleService.GetBulkById(b).subscribe((res: any) =>{
      this.invoicesList = res.PdfInvoiceList;
      console.log(this.invoicesList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openView(template: TemplateRef<any>,b, model?) {
    this.openModal(template);
    debugger
    this._uow.saleService.GetBulkById(b.Id).subscribe((res: any) => {
      this.bulkbyid = res;
      console.log(this.bulkbyid);
    }, (err) => {
      console.log(err);
     });
  }

  conformDelete() {
    this.spinner.show();
    this._uow.saleService.DeleteBulk(this.bulkInstallment).subscribe((res: any) => {
      this.getBulkList();
      this.spinner.hide();
      Swal.fire(
        'Deleted!',
        'Purchase Deleted!',
        'success'
      )
     this.modalRef.hide();
    }, (err) => {
      Swal.fire(
        'Failure!',
        'Purchase Not Deleted!',
        'error'
      )
      console.log(err);
      this.spinner.hide();
     });
  }

  EditPurchaseInstallment(b) {

    // this.bulkInstallment.TransactionDate = new Date(b.TransactionDate);
    // this.bulkInstallment.FK_G_CashAndBank_Id = new ItemViewModel();
    // this.bulkInstallment.FK_G_CashAndBank_Id.Id - b.FK_G_CashAndBank_Id.Id;
    // this.bulkInstallment.FK_G_CashAndBank_Id.Name = b.FK_G_CashAndBank_Id.Name;
    // this.bulkInstallment.TotalInvoiceAmount = b.TotalInvoiceAmount;
    // this.bulkInstallment.TotalPaidAmount = b.TotalPaidAmount;
    // this.bulkInstallment.TotalBalance = b.TotalBalance;
    // this.bulkInstallment.TotalAmount = b.TotalAmount;
    // this.bulkInstallment.Remarks = b.Remarks;
    // this.bulkInstallment.BulkInstallments.forEach(element => {
    //  let bulkDetail = this.bulkInstallment.BulkInstallments.find(x => x.G_FK_Sale_Id === b.G_FK_Sale_Id.Id);
    //  bulkDetail.G_FK_Sale_Id = new ItemViewModel(b.G_FK_Sale_Id.Id,b.G_FK_Sale_Id.Name);
    //  bulkDetail.G_FK_Sale_Id.Id = element.G_FK_Sale_Id.Id;
    //  bulkDetail.G_FK_Sale_Id.Name = element.G_FK_Sale_Id.Name;
    //  bulkDetail.InvoiceAmount = element.InvoiceAmount;
    //  bulkDetail.PaidAmount = element.PaidAmount;
    //  bulkDetail.RemainingAmount = element.RemainingAmount;
    //  bulkDetail.Amount = element.Amount;
    //  bulkDetail.TransactionDate = new Date(element.TransactionDate);
    // });
  // public searchTerm = '';
  // getBySearchTerm(e) {
  //   if (this.searchTerm !== ' ') {
  //     this._jq.showSpinner(true);
  //     this._uow.itemService.GetStockPagedBySearch(this.searchTerm).subscribe((res: any) => {
  //       this.vmListItems = res.DtlList;
  //       this._jq.showSpinner();
  //     }, (error) => {
  //       console.log(error);
  //       this._jq.showSpinner();
  //     });
  //   }
  // }


  }
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.bulkInstallment = new G_Bulk_Installment_View_Model();
      this.getBulkList();
    }
  }

  // getSortIndexList(sIndex) {
  //   debugger
  //   this._jq.showSpinner(true);
  //   this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
  //   this._uow.itemService.GetStockPagedResult(0, 50, null, sIndex, this.SortBy).subscribe((res: any) => {
  //     debugger
  //     this._jq.reinitDataTable(res);
  //     this.vmListItems = res.DtlList;;
  //     console.log(res);

  //     this._jq.showSpinner();
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

}
