import { Component, OnInit, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { G_SSCategoryViewModel } from 'src/app/viewmodels/g-settings/G_SSCategoryViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ss-category-form',
  templateUrl: './ss-category-form.component.html',
  styleUrls: ['./ss-category-form.component.scss']
})
export class SsCategoryFormComponent implements OnInit {
  @Input() public vm: G_SSCategoryViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  public itemtypes: any = [];
  selectedItemType = new ItemViewModel();
  modalRef: BsModalRef;

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new G_SSCategoryViewModel();
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Spare parts & Service Category';
    }
    console.log(this.vm);
    this.getItemTypes();
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
  }
  // getTagTypes() {
  //   this._uow.tagsTypeService.GetList().subscribe((res) => { this.itemtypes = res; }, (err) => console.log(err));
  // }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }

  openTagTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    this._uow.ssCategoryService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        Swal.fire(
          'Created!',
          'SS-Category Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'SS-Category Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });
  }

  openItemtypeModel(tempelate) {
    this.openModal(tempelate);
  }
  getItemTypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => {
      this.itemtypes = res;
      // this.selectedItemType = this.vm.FK_G_ItemType_Id.Id;
    }, (err) => {
      console.log(err);
    });
  }
  onItemTypeSubmitted(e) {
    if (e == true) {
      this.getItemTypes();
    }
  }

  checkSSCypeDublication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.ssCategoryService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

  onSSCSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.ngOnInit();
    }
  }
}
