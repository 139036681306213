import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public vm: G_PurchaseMasterViewModel = new G_PurchaseMasterViewModel();
  public vmI: G_Installment_View_Model = new G_Installment_View_Model();
  public vmListItems: any = [];
  public invoicesList: any = [];
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Purchase';
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }



  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.purchaseService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;
      this.vm = res.DtlList;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }

  navigateClick(){
    debugger
    this._router.navigate(['/purchase-dtl-view', {id: this.vm.Id}]);
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.purchaseService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    } else {
      this.getList();
    }
  }

  getSortIndexList(sIndex) {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.purchaseService.GetPagedResult(0, 50, null, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
      this.vm.TransactionDate = this._uow.defaultService.CutTimeZone(this.vm.TransactionDate);
    } else {
      this.vm = undefined;
    }
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.purchaseService.GetById(b).subscribe((res: any)=>{
      this.invoicesList = res.InvoicesList;
      console.log(this.invoicesList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  // extrnal components
  onItemSubmitted(e) {
    document.location.reload(true);
    console.log(e);
    if (e === true) {
      this.vm = new G_PurchaseMasterViewModel();
      this.getList();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.purchaseService.Delete(this.vm).subscribe((res: any) => {
      this.getList();
      this.spinner.hide();
      Swal.fire(
        'Deleted!',
        'Purchase Deleted!',
        'success'
      )
     this.modalRef.hide();
    }, (err) => {
      Swal.fire(
        'Failure!',
        'Purchase Not Deleted!',
        'error'
      )
      console.log(err);
      this.spinner.hide();
     });
  }

}
