import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { G_Warehouse_Viewmodel } from 'src/app/viewmodels/g-settings/G_Warehouse_Viewmodel';
import Swal from 'sweetalert2';
import { WareHouseItemViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  modalRef: BsModalRef;
  public itemVm: G_Warehouse_Viewmodel = new G_Warehouse_Viewmodel();
  public warehouseItemVmlist: Array<WareHouseItemViewModel> = [];
  public whVM = new WareHouseItemViewModel();
  public vmListItems: any = [];
  public component_Title = "Warehouse Management";
public FK_WareHouse_Id: any;
currentUser:any={};
name = 'CP_Excel';
id = 'Id';
checked = false;
disabled = false;
label = 'Toggle On/Off';
labelledby = 'Some Other Text';
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { 
    this.whVM.file = undefined;
    this.currentUser = this._uow.authenticationService.getUser();
  }

  ngOnInit() {
    console.log(this.currentUser);
    // this.getwareHouseItemList();
    this.getList();

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  openModalXL(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
    this.modalRef.setClass('modal-xl');
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.warehouseService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getwareHouseItemList(id) {
    // this._jq.showSpinner(true);
    // this.spinner.show();
    debugger;
    this._uow.wareHouseItemService.GetListItem(id).subscribe((res: Array<any>) => {
      // this._jq.reinitDataTable(res);
      res.forEach(element => {
        element.FK_WareHouse_Id = new ItemViewModel(this.FK_WareHouse_Id.Id, this.FK_WareHouse_Id.Name);
      });
      this.warehouseItemVmlist = res;
      console.log('list item', res);
      // this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this.spinner.hide();
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new G_Warehouse_Viewmodel();
    }
  }
 public openWareHouseItemListModal(template: TemplateRef<any>, FK_WareHouse_Id?) {
   this.FK_WareHouse_Id = new ItemViewModel(FK_WareHouse_Id.Id, FK_WareHouse_Id.Name);
   this.openModalXL(template);
   this.getwareHouseItemList(FK_WareHouse_Id.Id);

  }
  public openUploadExcelModal(template: TemplateRef<any>, FK_WareHouse_Id?) {
    this.whVM.FK_WareHouse_Id = new ItemViewModel(FK_WareHouse_Id.Id, FK_WareHouse_Id.Name);
    this.openModalXL(template);
   }
   onChangeToggle(event){
     console.log(this.checked);
     if (event){
       this.checked = true;
     }
     else{
       this.checked = false;
     }
   }
   async downloadExcel(){
        // tslint:disable-next-line: max-line-length
        await  this._uow.wareHouseItemService.GetG_SparePartsListforWareHouseCreateExcel(this.whVM.FK_WareHouse_Id.Id).toPromise().then((res: any) => {
          console.log('return url', res);
          if (res){
            this.goToLink(res.Name);
          }
        }).catch(async (error:any) =>{
         await Swal.fire('Failure!',
         'Error In Getting Url!',
         'error');
        });
   }
   goToLink(url: string){
    window.open(url, "_blank");
}
   uploadExcel(){
    this.spinner.show();
    this._uow.wareHouseItemService.SaveG_WareHouseItemByExcel(this.whVM).toPromise().then((res: any) => {
  this.spinner.hide();
  this.modalRef.hide();
  Swal.fire( 'Uploaded!',
  'Excel Uploaded!',
  'success');
}).catch(error => {
  this.spinner.hide();
  Swal.fire('Failure!',
  'Not Uploaded!',
  'error');
});
   }
   onclick(event){
    event.target.value = '';
    this.whVM.file = undefined;
  }
  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.warehouseService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
      Swal.fire(
        'Deleted!',
        'Warehouse Entry Deleted!',
        'success'
      )
      this.spinner.hide();
    }, (err) => {
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }
  public uploadFlag: boolean = false;
  attachfile(event){
    if (event.target.files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
      event.target.files[0].Name = '';
      this.uploadFlag = true;
      return;
    }
    else{
      this.uploadFlag = false;
      this.whVM.file = event.target.files[0];
    }
    console.log(event.target.files[0]);
    // event.target.files[0]
  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new G_Warehouse_Viewmodel();
      this.getList();
    }
  }
}
