import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
@Component({
  selector: 'app-purchase-dtl-view',
  templateUrl: './purchase-dtl-view.component.html',
  styleUrls: ['./purchase-dtl-view.component.scss']
})
export class PurchaseDtlViewComponent implements OnInit {

  public vm: G_PurchaseMasterViewModel = new G_PurchaseMasterViewModel();
  @Input() public vmI: G_Installment_View_Model = new G_Installment_View_Model();
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  private submitted = false;
  public component_Title = 'Purchase';
  public isPreview: boolean = false;
  public thumbnail: any;
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RefId',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1
  };
  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
    limitSelection: 1
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  disabled = true;
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public citemtype = 'itemtype';
  public itemTypes: any = [];
  public suppliers: any = [];
  public csuppliers = 'suppliers';
  public selectedSuppliers: any = [];
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public calingUrl: string;
  public urlId: number;
  public purchaseRequisitions: any = [];
  public cpurchaseRequisitions = 'purchaseRequisitions';
  public selectedPurchaseRequisitions: any = [];
  @Input() modalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router
  ) { }

ngOnInit() {
    debugger
    let selectedSuplier : any = [];
    let selectedSaprePartsList:any = [];
    this.vm = new G_PurchaseMasterViewModel();
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];
    });
    this._uow.purchaseService.GetById(this.urlId).subscribe((res: any)=>{
      this.vm = res;
      console.log(res);
      let sCA = new DefineAccountviewModel();
      sCA.Id = this.vm.FK_Sup_Cradit_Acc.Id;
      sCA.Acc_Titel = this.vm.FK_Sup_Cradit_Acc.Name;
      this.vmD.Acc_Titel = this.vm.FK_Dabit_Acc.Name;
      selectedSuplier.push(sCA);
      this.selectedSuppliers = selectedSuplier;

      this.vm.PurchaseDetail.forEach(x=> {
        let partssSelected = new GItemDefiningViewModel();
        partssSelected.Id = x.FK_G_Item_Id.Id;
        partssSelected.Name = x.FK_G_Item_Id.Name;
        selectedSaprePartsList.push(partssSelected);
        this.selectedSpareParts = selectedSaprePartsList;
  });
    },(err)=>{
      console.log(err);
    })

    if (this.modal_title == undefined) {
      this.modal_title = 'Purchase Order';
    }
    this.sparePartVm = new GItemDefiningViewModel();
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(0, ' ');

}

getRefid() {
  this._uow.purchaseOrderService.ValidatePurchsaeRefId().subscribe((res: any) => {
    console.log(res);
    this.vm.RefId = res.code;
  }, (error) => {
    console.log(error);
  });
}

getSpareParts() {
  //'Spare-Parts'
  let supllierrr = "Spare-Parts";
  this._uow.itemService.GetSSItemDefiningByItemType(supllierrr).subscribe((res: any) => {
    this.spareParts = res;
    console.log(res);

  }, (err) => {
    this._uow.logger.log(err);
    this.spareParts = [];
  });
}

getItemTypes() {
  this._uow.itemTypeService.GetList().subscribe((res: any) => {
    this.itemTypes = res;
  }, (err) => this._uow.logger.log(err));
}

getSuppliers() {
  let supllier = "Supplier";
  if (this.vm.PaymentType === 2) {
    supllier = "Cash";
  }

  this._uow.supplierService.GetListSuplier(supllier).subscribe((res: any) => {
    this.suppliers = res.DtlList;
    this._uow.logger.log(this.suppliers);
  }, (err) => this._uow.logger.log(err));
}

getRequisitions() {
  this._uow.purchaseOrderService.GetList().subscribe((res: any) => {
    console.log(res.DtlList);
    this.purchaseRequisitions = res.DtlList;
  }, (error) => {
    console.log(error);
  });
}

openModal(template: TemplateRef<any>) {
  this.modalRef = this._uow.modalServiceEx.show(template);
  this.modalRef.setClass('modal-xl');
}

openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
  this.openModal(template);
  this.vmI = new G_Installment_View_Model;

}

}
