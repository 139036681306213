import { Component, OnInit, TemplateRef } from '@angular/core';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ready-list',
  templateUrl: './ready-list.component.html',
  styleUrls: ['./ready-list.component.scss']
})
export class ReadyListComponent implements OnInit {
  myDate = new Date(Date.now());
  public component_Title = 'WIP Repair Orders';
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public attachmentList: any = [];
  public vmReadyList : any = [];
  modalRef: BsModalRef;
  p: number = 1;
  constructor(
    private datePipe: DatePipe,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this._uow.saleService.GetPagedResultReady().subscribe((res: any) => {
      this.vmReadyList = res.DtlList;
      this.vm = res.DtlList;

      console.log(this.vm);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }

  onButtonClick() {
    debugger
    this._jq.showSpinner(true);
    this._router.navigate(['/repair-invoice', {Id: this.vm.Id}]);
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.saleService.GetPagedBySearchReady(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmReadyList = res.DtlList;
        console.log(this.vmReadyList);
        // this._jq.showSpinner();
        this.spinner.hide();
      },  (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }
  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.saleService.GetById(b).subscribe((res: any)=>{
      this.attachmentList = res.AttachmentList;
      console.log(this.attachmentList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }
  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vm = new G_SaleMasterViewModel();
      this.getList();
    } else {
      this._jq.showSpinner();
    }
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.saleService.GetList().subscribe((res: any) => {
      this.vmListItems = res;
      console.log(res);
      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }

  conformDelete() {
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    } else {
      this.vm = new G_SaleMasterViewModel();
    }
  }

}
