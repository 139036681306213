import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from '../../common/default.service';
import { Paginator } from 'src/app/viewmodels/paginator';
import { Raw } from 'src/app/viewmodels/Raw';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { E_G_EPSStatusType } from 'src/app/viewmodels/g-purchase/E_G_EPSStatusType';
import { AppConsts } from 'src/app/common/app-consts';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';

@Injectable({
  providedIn: 'root'
})
export class SaleService implements IHttpService<G_SaleMasterViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _defaultService: DefaultService) {
    this._ep = new EndpointConfig('GSale');
  }
  ValidateG_SaleRefId() {
    return this._client.post(this._ep.getEndpointMethod('ValidateG_SaleRefId'), { Id: null });
  }

  GetList() {
    let pg = new Paginator(0, 50, null, 0);
    //pg.AccId = E_G_EPSStatusType.Purchase_Slip;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleById'), pg);
  }

  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    pg.AccId = E_G_EPSStatusType.SaleQout;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pg);
  }

  GetPagedResultOpen(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    pg.AccId = E_G_EPSStatusType.SaleWIP;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pg);
  }

  GetPagedResultPayment(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    pg.AccId = E_G_EPSStatusType.SaleInvoice;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pg);
  }

  GetPagedResultAllSales(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleAllList'), pg);
  }

  GetPagedSearchByAllSales(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleAllList'), pager);
  }

  GetAllSaleInvoices(id: any, td: Date, fd: Date) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleAllInvoicesByCustomer'),  { Id: id, ToDatee: td, FromDate: fd });
  }

  CreateAllSaleInvoicesExcel(obj: G_Installment_View_Model) {
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('CreateG_SaleAllInvoicesByCustomer'), fd, {responseType: 'text'});
  }


  GetSaleInvoices(id: any, td: Date) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleInvoicesByCustomer'),  { Id: id, ToDatee: td });
  }

  GetPagedDirectSale(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleByDirectSale'), pg);
  }

  GetPagedSearchByDirectSale(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleByDirectSale'), pager);
  }

  GetPagedResultReady(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    pg.AccId = E_G_EPSStatusType.SaleReady;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pg);
  }

  GetPagedResultByCompleted(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    let pg = new Paginator(start, pagesize, flag, sortIndex);
    pg.AccId = E_G_EPSStatusType.SaleCompleted;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pg);
  }

  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.SaleQout;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pager);
  }

  GetPagedBySearchWIP(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.SaleWIP;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pager);
  }

  GetPagedBySearchReady(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.SaleReady;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pager);
  }

  GetPagedSearchByCompleted(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.SaleCompleted;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pager);
  }

  GetPagedBySearchPayment(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.SaleInvoice;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleList'), pager);
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleById'), { Id: id });
  }

  Post(obj: G_SaleMasterViewModel) {
    console.log(obj);
    this._defaultService.dateTransFuckDatesInObject(obj);
    //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleQout);

    let fd = new FormData();
    debugger
    if(obj.file!=undefined){
      for (var i = 0; i < obj.file.length; i++) {
      fd.append('file', obj.file[i]);
      }
    }
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Sale'), fd);
  }

  PostSave(obj: G_SaleMasterViewModel) {
    console.log(obj);
    this._defaultService.dateTransFuckDatesInObject(obj);
    //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleCompleted);

    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Sale'), fd);
  }

  Put(obj: G_SaleMasterViewModel) {
    console.log(obj);
 
    this._defaultService.dateTransFuckDatesInObject(obj);
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleWIP);
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Sale'), fd);
  }

  PostReady(obj: Raw) {
    obj.Cid = E_G_EPSStatusType.SaleReady;
    return this._client.post(this._ep.getEndpointMethod('GetG_SaleStatusUpdate'), obj);
  }

  Delete(obj: G_SaleMasterViewModel) {
    //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Slip);
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Sale'), obj);
  }

  PostBulkPayment(obj: G_Bulk_Installment_View_Model){
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveBulkG_SP_Intallment'), fd);
  }

  GetBulkPaymentList(){
    let pg = new Paginator(0, 50, null, 0);
    //pg.AccId = E_G_EPSStatusType.Purchase_Slip;
    return this._client.post(this._ep.getEndpointMethod('GetG_BulkPaymentList'), pg);
  }

  GetBulkById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SP_BulkPaymentById'), { Id: id });
  }

  DeleteBulk(obj: G_Bulk_Installment_View_Model) {
    //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Slip);
    return this._client.post(this._ep.getEndpointMethod('DeleteG_SP_BulkIntallment'), obj);
  }

}

