import { Injectable } from '@angular/core';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagsTypeService implements IHttpService<G_TagsTypeViewModel>  {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_TagsTypeList'));
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_TagsTypeById'), { Id: id });
  }
  Post(obj: G_TagsTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_TagsType'), obj);
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckTagsTypeDublication'), { Code: code });
  }

  Put(obj: G_TagsTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_TagsType'), obj);
  }

  Delete(obj: G_TagsTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_TagsType'), obj);
  }
}
