import { ItemViewModel } from '../ItemViewModel';

export class GAggingReportViewmodel {
        public Fk_Customer_Id : ItemViewModel;
        public TotalInvoiceAmount : number;
        public TotalPaidAmount : number;
        public TotalRemainingAmount : number;
        public WeekAmount : number;
        public FirstMonthAmount : number;
        public SecondMonthAmount : number;
        public ThirdMonthAmount : number;
        public FourthSixthMonthsAmount : number;
        public AfterSixmonthAmount : number;
}
