import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { G_ModelsViewModel } from 'src/app/viewmodels/g-settings/G_ModelsViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { G_FuelTypeViewModel } from 'src/app/viewmodels/g-settings/G_FuelTypeViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-model-form',
  templateUrl: './model-form.component.html',
  styleUrls: ['./model-form.component.scss']
})
export class ModelFormComponent implements OnInit {
  @Input() public vm: G_ModelsViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  public fuleTypes: ItemViewModel[] = [];
  public brands: ItemViewModel[] = [];
  modalRef: BsModalRef;
  selectedFuleType: any = {};
  selectedBrand: any = {};
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    if (this.modal_title == undefined) {
      this.modal_title = "Vechicle Model";
    }
    if (this.vm == undefined) {
      this.vm = new G_ModelsViewModel();
    }
    this.getBrands();
    this.getFuleTypes();
  }

  getBrands() {
    this._uow.brandService.GetList().subscribe((res: any) => {
      this.brands = res;
      this.selectedBrand = this.vm.Fk_G_Brand_Id;
    }, (err) => console.log(err));
  }
  getFuleTypes() {
    this._uow.fuleTypeService.GetList().subscribe((res: any) => {
      this.fuleTypes = res;
      // this.selectedFuleType = this.vm.FK_G_FuelType_Id;
    }, (err) => console.log(err));
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
  }
  openBrandsModal(template: TemplateRef<any>) {
    this.openModal(template);
  }
  openFuleTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }
  brandChanged(e) {
    console.log(e);
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    // this.vm.FK_G_FuelType_Id = new ItemViewModel(this.selectedFuleType.Id);
    // this.vm.Fk_G_Brand_Id = new ItemViewModel(this.selectedBrand.Id);
    this._uow.modelService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        Swal.fire(
          'Created!',
          'Vehicle Model Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Vehicle Model Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });

  }

  onBrandChange(e) {
    console.log(e);
    // this.vm.Fk_G_Brand_Id = new ItemViewModel(e.Id, e.Name);
  }
  onFuleTypeChange(e) {
    // this.vm.FK_G_FuelType_Id = new ItemViewModel(e.Id, e.Name);
    console.log(e);
  }

  onfuleTypeSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.getFuleTypes();
    }
  }
  onBrandSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.getBrands();
    }
  }

  checkModelDublication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.modelService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

  openTagTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  onModelSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.ngOnInit();
    }
  }
}
