import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root'
})
export class NgxBootstrapModalExt {
    private globalconfig = {
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    public config: any = undefined;
    constructor(private _bsService: BsModalService) {

    }
    show(tempelate: TemplateRef<any>) {
        return this._bsService.show(tempelate, (this.config == undefined ? this.globalconfig : this.config));
    }

    setConfig(conf) {
        this.config = conf;
    }
}
