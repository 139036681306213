import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { G_ItemTypeViewModel } from 'src/app/viewmodels/g-settings/G_ItemTypeViewModel';
import { HttpClient } from '@angular/common/http';
import { IHttpService } from '../../common/IHttpService';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeService implements IHttpService<G_ItemTypeViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_ItemTypeList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_ItemTypeDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ItemTypeById'), { Id: id });
  }
  Post(obj: G_ItemTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_ItemType'), obj);
  }

  Put(obj: G_ItemTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_ItemType'), obj);
  }

  Delete(obj: G_ItemTypeViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_ItemType'), obj);
  }

}
