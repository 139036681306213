import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { G_ModelsViewModel } from 'src/app/viewmodels/g-settings/G_ModelsViewModel';

@Injectable({
  providedIn: 'root'
})
export class ModelService implements IHttpService<G_ModelsViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_ModelsList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_ModelDublication'), { Code: code });
  }

  GetModelsByBrandId(brandId: number) {
    return this._client.post(`${this._ep.getEndpointMethod('GetG_ModelsByBrandId')}`, { BrandId: brandId });
  }
  GetModelsListByBrands(Ids: number[]) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ModelsListByBrands'), { BrandIds: Ids });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ModelsById'), { Id: id });
  }
  Post(obj: G_ModelsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Models'), obj);
  }

  Put(obj: G_ModelsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Models'), obj);
  }

  Delete(obj: G_ModelsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Models'), obj);
  }

}
