import { ItemViewModel } from '../ItemViewModel';

export class G_InventoryTransferViewModel{
    public  Id :number
    public  FK_From_WareHouse_Id :ItemViewModel;
    public  FK_To_WareHOuse_Id :ItemViewModel; 
    public  TransferDate :Date;
    public  FK_Client_Id :ItemViewModel;
    public InventoryDetailList: Array<G_InventoryDetailsViewModel>;
}
export class G_InventoryDetailsViewModel{
        public  Id :number;
        public  FK_G_Transfer_Id :ItemViewModel;
        public  FK_Item_Id :ItemViewModel;
        public  Quantity :number;
        public  CostOfGoodSold :number;
        public  FromOldQuantity :number;
        public  FromNewQuantity :number;
        public  TransferQuantity :number;
        public  ToOldQuantity :number;
        public  ToNewQuantity: number; 
        public  FK_Client_Id :ItemViewModel;
}