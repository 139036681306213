import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public set(name: string, item: any) {
    localStorage.setItem(name, JSON.stringify(item));
  }
  public get(name: string) {
    return JSON.parse(localStorage.getItem(name));
  }

  public remove(name: string) {
    localStorage.removeItem(name);
  }

  public checkClaim(claim: string) {
    return this.get('ussr').Claims.includes(claim); //true;
  }
}
