import { Injectable } from '@angular/core';
import { G_ManufactureViewModel } from 'src/app/viewmodels/g-settings/G_ManufactureViewModel';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService implements IHttpService<G_ManufactureViewModel>  {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_ManufactureList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_ManufactureDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ManufactureById'), { Id: id });
  }
  Post(obj: G_ManufactureViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Manufacture'), obj);
  }

  Put(obj: G_ManufactureViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Manufacture'), obj);
  }

  Delete(obj: G_ManufactureViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Manufacture'), obj);
  }
}
