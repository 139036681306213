import { ItemViewModel } from '../ItemViewModel';

export class PurchaseDetailViewModel {
    public Id: number;
    public FK_G_PurchaseMast_Id: ItemViewModel;
    public Item_Code: string;
    public Item_Name: string;
    public Item_Type: string;
    public FK_G_Item_Id: ItemViewModel;
    public Item_Detail: string;
    public Quantity: number;
    public AvalibelQuantity: number;
    public CurrentStock: number;
    public MaxStock: number;
    public MinStock: number;
    public MaxQuantity: number;

    public AvalibilityStatus: boolean;
    public UOM: string;
    public Remarks: string;
    public PurchasePrice: number;
    public SalePrice: number;
    public CostofGoodSold: number;
    public VAT_Type: string;
    public VAT_Value: number;
    public VatAmount: number;
    public TotalAmount: number;
    public DiscountAmount: number;
    public Discount: number;
    public Batch: number;
    public OldMast: number;
    public FK_ClientId: ItemViewModel;
}
