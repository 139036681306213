import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tags-form',
  templateUrl: './tags-form.component.html',
  styleUrls: ['./tags-form.component.scss']
})
export class TagsFormComponent implements OnInit {
  @Input() public vm: G_TagsViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;

  public tagTypes: any = [];
  modalRef: BsModalRef;
  public IsDublicate: boolean = false;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new G_TagsViewModel();
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Tags';
    }
    console.log(this.vm)
    this.getTagTypes();
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
  }
  getTagTypes() {
    this._uow.tagsTypeService.GetList().subscribe((res) => { this.tagTypes = res; }, (err) => console.log(err));
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }
  openTagTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    debugger
    this._uow.tagsService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        this._jq.showSpinner(true);
        Swal.fire(
          'Created!',
          'Tags Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Tags Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
        this._jq.showSpinner(false);
      });
  }

  checkTagDublication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.tagsService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

  onTagTypeSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.getTagTypes();
    }
  }
}
