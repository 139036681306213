import { Component, OnInit, Input } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { JqHelper } from "src/app/common/jq-helper";
import { UnitOfWorkService } from "src/app/services/api/uow/unit-of-work.service";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import {
  G_InventoryTransferViewModel,
  G_InventoryDetailsViewModel,
} from "src/app/viewmodels/g-inventory/inventory-transfer.viewmodel";
import Swal from "sweetalert2";

@Component({
  selector: "app-stock-form",
  templateUrl: "./stock-form.component.html",
  styleUrls: ["./stock-form.component.scss"],
})
export class StockFormComponent implements OnInit {
  @Input() public bsmRef: BsModalRef;
  public inventoryTransferVm = new G_InventoryTransferViewModel();
  public InventoryDetailList: Array<G_InventoryDetailsViewModel> = [];
  public SortBy: string = "asc";
  public modalRef: BsModalRef;
  public userAssingedWareHouse: Array<any> = [];
  public selectedStock: Array<any>;
  public maxDate = new Date();
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.inventoryTransferVm.TransferDate = new Date();
  }

  ngOnInit() {
    this.getWareHouse();
  }
  getWareHouse() {
    this._uow.warehouseService.GetListByUserId().subscribe((res: any) => {
      console.log("warehouse", res);
      this.userAssingedWareHouse = res;
    });
  }
  public isSame: boolean = false;
  public isSelect: boolean = false;
  onSelectWarehouse() {
    if (
      this.inventoryTransferVm.FK_From_WareHouse_Id !== undefined &&
      this.inventoryTransferVm.FK_To_WareHOuse_Id !== undefined
    ) {
      if (
        this.inventoryTransferVm.FK_From_WareHouse_Id.Id ===
        this.inventoryTransferVm.FK_To_WareHOuse_Id.Id
      ) {
        this.isSame = true;
      } else {
        this.isSame = false;
      }
      this.isSelect = true;
    }
  }
  fetchListByWarehouse() {
    this.loading = true;
    let raw = {
      Id: this.inventoryTransferVm.FK_From_WareHouse_Id.Id,
      Idd: this.inventoryTransferVm.FK_To_WareHOuse_Id.Id,
    };
    this._uow.itemService.GetG_TransferInventoryListByWareHouse(raw).subscribe(
      (res: any) => {
        console.log("res", res);
        this.InventoryDetailList = res;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }
  transferStock(stock,index) {
    if(stock.TransferQuantity > stock.FromOldQuantity){
      console.log('runnng');
      return;
   }
    if (this.selectedStock == undefined ||  this.selectedStock.length < 1) {
      this.selectedStock =[];
    }
    
    console.log(stock.TransferQuantity);
    
    if (this.selectedStock.length > 0) {
      
      let selected = this.selectedStock.find(
        (x) => x.FK_Item_Id.Id === stock.FK_Item_Id.Id
      );
      if(selected !== undefined){
        
        if (stock.TransferQuantity > 0) {
            
          selected.FromNewQuantity = stock.FromOldQuantity - stock.TransferQuantity;
          selected.ToNewQuantity = stock.ToOldQuantity + stock.TransferQuantity;
          // this.selectedStock.push(selected);
          console.log("selected array", this.selectedStock);
        }
        else{
          this.selectedStock.splice(this.selectedStock.indexOf(selected),1);  
        }
      }
      else{
        if (stock.TransferQuantity > 0) {
          stock.FromNewQuantity = stock.FromOldQuantity - stock.TransferQuantity;
          stock.ToNewQuantity = stock.ToOldQuantity + stock.TransferQuantity;
          this.selectedStock.push(stock);
        }
      }
    } else {
      
      if (stock.TransferQuantity > 0) {
        stock.FromNewQuantity = stock.FromOldQuantity - stock.TransferQuantity;
        stock.ToNewQuantity = stock.ToOldQuantity + stock.TransferQuantity;
        this.selectedStock.push(stock);
      }
    }
   
  }
  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.inventoryTransferVm.InventoryDetailList = this.selectedStock;
    this._uow.itemService
      .SaveG_TransferInventory(this.inventoryTransferVm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.spinner.hide();
        Swal.fire("Created!", "Stock Created/Updated!", "success");
        this.bsmRef.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire("Failure!", "Stock Not Updated!", "error");
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
      });
  }
}
