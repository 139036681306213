import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit {
  @Input() public vm: GVechileViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  public IsRDublicate: boolean = false;
  public IsCDublicate: boolean = false;
  public IsEDublicate: boolean = false;
  modalRefLv1: BsModalRef;
  modalRef: BsModalRef;

  public cbrands = 'brands';
  public brands: any = [];

  public cmodels = 'models';
  public models: any = [];

  public cCustomers = 'customers';
  public customers: any[] = [];

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new GVechileViewModel();
    } else {
      this.brandChanged(null);
    }

    if (this.modal_title == undefined) {
      this.modal_title = 'Vehicle';
    }
    this._uow.logger.log(this.vm);
    this.getBrands();
    this.getCustomers();
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRefLv1 = this._uow.modalServiceEx.show(template);
    }
  }
  getModels() {
    this.spinner.show();
    this._uow.modelService.GetList().subscribe((res: any) =>
    {
      this.models = res;
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getCustomers() {
    this.spinner.show();
    this._uow.customerService.GetList().subscribe((res: any) => {
      this.customers = res.DtlList;
      console.log(res);
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
  getBrands() {
    this._uow.brandService.GetList().subscribe((res: any) => {
      this.brands = res;
    }, (err) => console.log(err));
  }
  brandChanged(e) {
    this.models = [];
    this._uow.modelService.GetModelsByBrandId(this.vm.Fk_G_Brand.Id).subscribe((res) => {
      console.log(res);
      this.models = res;
    }, (error) => console.log(error));

  }


  saveOrUpdate() {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.vehicleService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Vehicle Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Vehicle Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
        console.log(error);
        this.spinner.hide();
      });
  }

  checkTagDublication(tagName,filter){
    this._jq.showSpinner(true);
    this._uow.vehicleService.checkVehicleDublications(tagName, filter).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
        if (filter == 1) {
          this.IsRDublicate = false
        }
        if (filter == 2) {
          this.IsCDublicate = false
        }
        if (filter == 3) {
          this.IsEDublicate = false
        }
      }else{
        this.IsDublicate = true;
        if (filter == 1) {
          this.IsRDublicate = true
        }
        if (filter == 2) {
          this.IsCDublicate = true
        }
        if (filter == 3) {
          this.IsEDublicate = true
        }
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }


  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }
  // random unwanted fucks
  public toggleState = false;
  toggleVisbility() {
    this.toggleState = !this.toggleState;
  }
  // extrnal components
  onItemSubmitted(e, mf) {
    console.log(e);
    if (e === true) {
      if (mf == this.cbrands) {
        this.getBrands();
      }
      if (mf == this.cmodels) {
        this.getModels();
      }
      if (mf == this.cCustomers) {
        this.getCustomers();
      }
    }
  }
}
